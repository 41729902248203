import { ApiService } from '../../../services/api.service';
import { GET_UNIVERSITY_LIST_REQUEST, GET_UNIVERSITY_LIST_SUCCESS, GET_UNIVERSITY_LIST_FAILURE, RESET } from './cmsSitesConstants';
import { alertActions } from '../../alert/alertActions';
import { showAlert } from '../../alert/showAlert';

// Get preferences
export function getUniversityList(data) {
    return dispatch => {
        dispatch(getUniversityListRequested());
        ApiService.callGetUniversityList(data).then(res => {
            if (res.data.status == 1) {
                dispatch(getUniversityListSuccess(res.data));
            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));
                dispatch(getUniversityListFailure(res.data));
            }
        })
            .catch(err => {
                dispatch(getUniversityListFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

const getUniversityListRequested = () => ({
    type: GET_UNIVERSITY_LIST_REQUEST
});

const getUniversityListSuccess = data => ({
    type: GET_UNIVERSITY_LIST_SUCCESS,
    payload: {
        ...data
    }
});
const getUniversityListFailure = error => ({
    type: GET_UNIVERSITY_LIST_FAILURE,
    payload: {
        error
    }
});

const reset = () => ({
    type: RESET
});
