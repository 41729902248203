

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET = 'RESET';

export const ViewConstants = {
    TITLE: 'Having trouble logging in?',
    SUCCESS_TITLE: 'Please check your inbox',
    RECOVERY_CONFIRM_MESSAGE: 'Forgot your password? Enter your email address and we’ll send you a recovery link.',
    // RECOVERY_CONFIRM_MESSAGE_SECOND: 'and we’ll send you a recovery link.',
    USERNAME_OR_EMAIL: 'Username or email',
    SEND_PASSWORD_RESET: `SEND PASSWORD RESET`,
    BACK_TO_LOGIN: 'Back to log in',
    GENERAL_ERROR_MESSAGE: 'Please double check your email and try again.',
    FORGOT_PASSWORD_SUCCESS_MESSAGE: 'A password reset link was emailed to ',
}