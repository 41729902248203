import React, { useState, useContext } from 'react';
import { forgotPassword, forgotPasswordReset } from './forgotPasswordActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Loader from '../../components/loader/loader';
import validator from 'validator';
import Cookies from 'js-cookie';
import { history } from '../../../helpers/history';
import { SignInForm, UserType } from '../../constants/constants';
import { ViewConstants } from './forgotPasswordConstants';
import AppContext from '../../components/appContext/appContext';


const ForgotPassword = (props) => {

    const myContext = useContext(AppContext);

    const [inputs, setInputs] = useState({
        emailId: Cookies.get(SignInForm.EMAIL_ID),
        userType: props.location.state.admin ? UserType.ADMIN : UserType.STUDENT
    });

    const { emailId } = inputs;

    const [showError, setShowError] = useState(false);

    const loading = useSelector(state => state.forgotPasswordData.loading);

    const success = useSelector(state => state.forgotPasswordData.success);

    const dispatch = useDispatch();

    // Input views action

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(({ ...inputs, [name]: value }));
    }

    // forgot password api

    const handleRecoveryLink = (e) => {

        if (emailId && validator.isEmail(emailId)) {
            setShowError(false)
            dispatch(forgotPassword(inputs));
        }
        else {
            setShowError(true)
        }
    }

    // Login navigation

    const handleLogin = (e) => {
        dispatch(forgotPasswordReset());
        if (props.location.state.admin == true) {
            history.push('/admin/login')
        }
        else {
            history.push('/login')
        }
    }

    return (
        <div>
            <div className='main-top' />
            <div className="main-box">
                <div className='box-container'>
                    {loading && <Loader />}
                    <div className={'main-header ' + (showError ? 'main-header-bottom-error' : 'main-header-bottom')}>
                        {success ? ViewConstants.SUCCESS_TITLE : ViewConstants.TITLE}
                    </div>
                    {showError &&
                        <div className='error-label'>
                            {ViewConstants.GENERAL_ERROR_MESSAGE}
                        </div>
                    }
                    {!success ? <div className='column'>
                        <div className='info-label-forgot sub-input-bottom'>
                            {ViewConstants.RECOVERY_CONFIRM_MESSAGE}
                        </div>
                        <div className='input-fld-container input-bottom-pad'>
                            <input
                                type="text"
                                name='emailId'
                                className={'input-fld input-fld-width ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                                onChange={handleChange}
                                value={emailId}
                                required
                            />
                            <span class="floating-label">{ViewConstants.USERNAME_OR_EMAIL}</span>
                        </div>
                        <Button className='primary-btn field-btn-top' style={myContext.buttonStyle} onClick={handleRecoveryLink}>
                            {ViewConstants.SEND_PASSWORD_RESET}
                        </Button>
                    </div> :
                        <div className='confirm-holder'>
                            <div className='sub-header-confirm sub-header-bottom'>
                                <div className='sub-header-normal-confirm'>
                                    {ViewConstants.FORGOT_PASSWORD_SUCCESS_MESSAGE}
                                </div>
                                <div className='sub-header-bold-confirm'>
                                    {emailId}
                                </div>
                            </div>
                            <div className='solid-line'>
                            </div>
                        </div>
                    }

                    <div className={'link-btn blue-link-btn lnk-btn-bottom ' + (!success && 'link-btn-top')} onClick={handleLogin}>
                        {ViewConstants.BACK_TO_LOGIN}
                    </div>
                </div>
            </div>
        </div >


    );

}
export default (ForgotPassword);


