

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const RESET = 'RESET';

export const ViewConstants = {
    TITLE: 'Change your password',
    SUCCESS_TITLE: 'Password has been updated',
    OLD_PASSWORD: 'Old password',
    NEW_PASSWORD: 'New password',
    CONFIRM_NEW_PASSWORD: 'Confirm new password',
    CANCEL: 'Cancel',
    SAVE: 'SAVE',
    BACK_TO_LOGIN: 'Back to log in',
    GENERAL_ERROR_MESSAGE: 'Please double check your details and try again.',
    CHANGE_PASSWORD_SUCCESS_MESSAGE: 'Please log in with your new password.',
    PASSWORD_MISMATCH_ERROR_MESSAGE: 'Please make sure your passwords match'
}