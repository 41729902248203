import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import GeneralReducer from '../general/generalReducer';
import LoginReducer from '../pages/login/loginReducer';
import SignupReducer from '../pages/signup/signupReducer';
import ResetPasswordReducer from '../pages/resetPassword/resetPasswordReducer';
import VerifyEmailReducer from '../pages/verifyEmail/verifyEmailReducer';
import ForgotPasswordReducer from '../pages/forgotPassword/forgotPasswordReducer';
import ChangePasswordReducer from '../pages/changePassword/changePasswordReducer';
import PreferencesReducer from '../pages/preferences/preferencesReducer';
import CmsSitesReducer from '../pages/admin/cmsSites/cmsSitesReducer';


//One root reducer for the whole app. This is done so that the app will have one single reducer to manage lots of other resources.
// And also communication between the reducers will be easier to maintain.

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    generalData: GeneralReducer,
    loginData: LoginReducer,
    signupData: SignupReducer,
    resetPasswordData: ResetPasswordReducer,
    verifyEmailData: VerifyEmailReducer,
    forgotPasswordData: ForgotPasswordReducer,
    changePasswordData: ChangePasswordReducer,
    preferencesData: PreferencesReducer,
    cmsSitesReducerData: CmsSitesReducer

});
export default createRootReducer;
