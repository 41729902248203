export const AuthConstants = {
    //cabot account
    GOOGLE_CLIENT_ID: '3841308257-lgautjvbr5vq4mpojpfup6a9j8v6icne.apps.googleusercontent.com',
    //cabot sb mail
    // GOOGLE_CLIENT_ID: '141816333849-smea8caifp5uu8lbo4jj8c4m2eb787uc.apps.googleusercontent.com',
    // FACEBOOK_APP_ID: '838645140207369',
    // MICROSOFT_CLIENT_ID: 'e88fc2a4-b036-49c5-af4f-1585041d8e6c',
    //From Student bridge
    // GOOGLE_CLIENT_ID: '109031057052-kc0bpnt6ttf959i335tma08flbg9b806.apps.googleusercontent.com',
    FACEBOOK_APP_ID: '345971739971232',
    MICROSOFT_CLIENT_ID: 'dae555ed-334f-4fad-b7ee-0a75d73dff1f',

    LOGIN_WITH_GOOGLE: 'Login with Google',
    LOGIN_WITH_FB: 'Login with Facebook',
    LOGIN_WITH_MICROSOFT: 'Login with Microsoft',
    LOGIN_WITH_TWITTER: 'Login with Twitter',

    SIGN_UP_WITH_GOOGLE: 'Sign up with Google',
    SIGN_UP_WITH_FB: 'Sign up with Facebook',
    SIGN_UP_WITH_MICROSOFT: 'Sign up with Microsoft',
    SIGN_UP_WITH_TWITTER: 'Sign up with Twitter',

    TYPE_GOOGLE: 'google',
    TYPE_FACEBOOK: 'facebook',
    TYPE_MICROSOFT: 'microsoft',
}