import React, { useState, useEffect, useContext } from 'react';
import { signup } from './signupActions';
import { authLogin } from '../login/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Loader from '../../components/loader/loader';
import validator from 'validator';
import { history } from '../../../helpers/history';
import { PasswordValidations, OR, OAUTH_SECRET, UserType, RETURL_URL } from '../../constants/constants';
import { ViewConstants } from './signupConstants';
import { hasMinCharacters, hasMaxCharacters, hasNumber, hasSpecial, hasUpperCase, hasFirstCharacterSpace } from '../../../helpers/validations';
import { Google, Facebook, Microsoft, Twitter } from '../../components/OAuth2/OAuth2';
import Cookies from 'js-cookie';
import AppContext from '../../components/appContext/appContext';

const Signup = () => {

    const myContext = useContext(AppContext);
    const [inputs, setInputs] = useState({
        emailId: '',
        password: '',
        firstName: '',
        lastName: '',
        userType: UserType.STUDENT
    });
    const [termsAgreed, setTermsAgreed] = useState(false);

    const [showError, setShowError] = useState(false);

    const [minimumCharactors, setMinimumCharactersError] = useState(true);
    const [maximumCharaters, setMaximumCharatersError] = useState(false);
    const [number, setNumberError] = useState(true);
    const [specialCharacter, setSpecialCharacterError] = useState(true);
    const [upperCase, setUpperCaseError] = useState(true);
    const [firstCharacterSpace, setFirstCharacterSpaceError] = useState(false);

    const [passwordError, setPasswordError] = useState(false);
    const [termsAndPrivacyError, setTermsAndPrivacyError] = useState(false);

    const [errorMessage, setErrorMessage] = useState(ViewConstants.SIGN_UP_ERROR_MESSAGE);

    const { emailId, firstName, lastName, password } = inputs;

    const loading = useSelector(state => state.signupData.loading);
    const loginLoading = useSelector(state => state.loginData.loading);

    const dispatch = useDispatch();

    // Input views action 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(({ ...inputs, [name]: value }));
        if (name == 'password') {
            if (hasMinCharacters(value).status) {
                setMinimumCharactersError(false)
            }
            else {
                setMinimumCharactersError(true)
            }
            if (hasMaxCharacters(value).status) {
                setMaximumCharatersError(false)
            }
            else {
                setMaximumCharatersError(true)
            }
            if (hasNumber(value).status) {
                setNumberError(false)
            }
            else {
                setNumberError(true)
            }
            if (hasSpecial(value).status) {
                setSpecialCharacterError(false)
            }
            else {
                setSpecialCharacterError(true)
            }
            if (hasUpperCase(value).status) {
                setUpperCaseError(false)
            }
            else {
                setUpperCaseError(true)
            }
            if (hasFirstCharacterSpace(value).status) {
                setFirstCharacterSpaceError(false)
            }
            else {
                setFirstCharacterSpaceError(true)
            }
        }
    }

    // Password validation view handling

    const handlePasswordFocus = () => {
        setPasswordError(true)
    }

    const handlePasswordBlur = () => {
        setPasswordError(false)
    }

    // Terms check box action

    const handleCheckbox = () => {
        setTermsAgreed(!termsAgreed)
    }

    // Sign up action

    const handleSignup = (e) => {

        if (emailId && password && firstName && lastName && validator.isEmail(emailId)) {
            if (checkPasswordError()) {
                setErrorMessage(ViewConstants.SIGN_UP_ERROR_MESSAGE)
                setShowError(true)
                setPasswordError(true)
                if (termsAgreed == false) {
                    setTermsAndPrivacyError(true)
                }
                else {
                    setTermsAndPrivacyError(false)
                }
            }
            else if (termsAgreed == false) {
                setErrorMessage(ViewConstants.TERMS_AND_PRIVACY_ERROR_MESSAGE)
                setShowError(false)
                setPasswordError(false)
                setTermsAndPrivacyError(true)
            }
            else {
                setShowError(false)
                setPasswordError(false)
                setTermsAndPrivacyError(false)
                setErrorMessage(ViewConstants.SIGN_UP_ERROR_MESSAGE)
                dispatch(signup(inputs));
            }
        }
        else {
            setErrorMessage(ViewConstants.SIGN_UP_ERROR_MESSAGE)
            setShowError(true)
            if (termsAgreed == false) {
                setTermsAndPrivacyError(true)
            }
            else {
                setTermsAndPrivacyError(false)
            }
        }

    }

    // Login navigation

    const handleLogin = (e) => {
        history.push('/login')
    }

    // Terms of service action

    const handleTermsOfService = (e) => {

    }

    // Privacy policy action

    const handlePrivacyPolicy = (e) => {
        window.open(myContext.privacyPolicy);

    }

    // Password validation error handling
    const checkPasswordError = () => {
        if (minimumCharactors || maximumCharaters || number || specialCharacter || upperCase || firstCharacterSpace) {
            return true
        }
        return false
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        //To redirect back to StudentBridge product
        if (query.get(RETURL_URL) != null) {
            Cookies.set(RETURL_URL, query.get(RETURL_URL))
        }

        const oauth_token = query.get('oauth_token')
        const oauth_verifier = query.get('oauth_verifier')
        if (oauth_token && oauth_verifier) {
            const data = {
                oauth_token: oauth_token,
                oauth_verifier: oauth_verifier,
                oauth_secret: Cookies.get(OAUTH_SECRET),
            }
            // call final auth login api 
            dispatch(authLogin(data));
        }
    }, []);

    return (
        <div>
            <div className='main-top' />
            <div className="main-box">
                <div className='box-container'>
                    {loading && <Loader />}
                    {loginLoading && <Loader />}
                    <div className={'main-header ' + ((showError || termsAndPrivacyError) ? 'main-header-bottom-error' : 'main-header-bottom')}>
                        {ViewConstants.SIGN_UP_TITLE}
                    </div>
                    {(showError || termsAndPrivacyError) &&
                        <div className='error-label'>
                            {errorMessage}
                        </div>
                    }
                    <div className='input-fld-container input-bottom-pad'>
                        <input
                            type='text'
                            name='emailId'
                            className={'input-fld input-fld-width ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                            onChange={handleChange}
                            value={emailId}
                            required
                        />
                        <span class="floating-label">{ViewConstants.EMAIL_ADDRESS}</span>
                    </div>
                    <div className='row'>
                        <div className='input-fld-container input-bottom-pad'>
                            <input
                                type='text'
                                name='firstName'
                                className={'input-fld name-fld-width name-margin-right ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                                onChange={handleChange}
                                value={firstName}
                                required
                            />
                            <span class='floating-label'>{ViewConstants.FIRST_NAME}</span>
                        </div>
                        <div className='input-fld-container input-bottom-pad'>
                            <input
                                type='text'
                                name='lastName'
                                className={'input-fld name-fld-width name-margin-left ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                                onChange={handleChange}
                                value={lastName}
                                required
                            />
                            <span class="floating-label last-name-padding">{ViewConstants.LAST_NAME}</span>
                        </div>
                    </div>
                    <div className='input-fld-container'>
                        <input
                            type='password'
                            name='password'
                            className={'input-fld input-fld-width input-fld-border ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                            onChange={handleChange}
                            onFocus={handlePasswordFocus}
                            onBlur={handlePasswordBlur}
                            value={password}
                            required />
                        <span class='floating-label'>{ViewConstants.CREATE_PASSWORD}</span>
                    </div>

                    <div className='row align-center link-btn-padding checkbox-helper-container create-helper-margin-left'>
                        <input type="checkbox" name="termsAgreed" value="termsAgreed" class='large margin-right' checked={termsAgreed == true} onChange={handleCheckbox} >
                        </input>

                        <div className={termsAndPrivacyError ? 'helper-info-label-error' : 'helper-info-label'}>
                            {ViewConstants.CREATE_ACCOUNT_HELPER_TEXT}
                        </div>
                    </div>
                    <div className='row checkbox-helper-container terms-privacy-margin'>
                        <div className={'margin-right left-align ' + (termsAndPrivacyError ? 'link-btn-error' : 'link-btn')} onClick={handleTermsOfService}>
                            {ViewConstants.TERMS_OF_SERVICE}
                        </div>
                        <div className={'margin-right ' + (termsAndPrivacyError ? 'info-label-error' : 'info-label')}>
                            {ViewConstants.AND}
                        </div>
                        <div className={termsAndPrivacyError ? 'link-btn-error' : 'link-btn'} onClick={handlePrivacyPolicy}>
                            {ViewConstants.PRIVACY_POLICY}
                        </div>
                    </div>
                    <div className='link-btn-padding'>
                        <Button className='primary-btn' style={myContext.buttonStyle} onClick={handleSignup}>
                            {ViewConstants.CREATE_ACCOUNT}
                        </Button>
                    </div>
                    <div className='row helper-padding'>
                        <div className='info-label margin-right'>
                            {ViewConstants.LOG_IN_HELPER_TEXT}
                        </div>
                        <div className='link-btn' onClick={handleLogin}>
                            {ViewConstants.LOG_IN}
                        </div>
                    </div>
                    <div className='row bottom-pad'>
                        <div className='dash-line' />
                        <div className='info-label split-label-padding' >
                            {OR}
                        </div>
                        <div className='dash-line' />
                    </div>
                    <div className='auth-btn-container oauth-bottom-padding'>
                        <Google />
                        <Facebook />
                        <Microsoft />
                        <Twitter />
                    </div>
                    {passwordError &&
                        <div className={'password-container password-margin-left ' + (showError ? 'password-container-top-error' : 'password-container-top')}>
                            <div>
                                <div className='password-box'>
                                    <div className='password-header'>
                                        {PasswordValidations.PASSWORD_VAIDATION_TITLE}
                                    </div>
                                    <div className='row'>
                                        <div className={password ? minimumCharactors ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                        </div>
                                        <div className='password-validation-label'>
                                            {PasswordValidations.MINIMUM_8_CHARACTERS}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className={password ? maximumCharaters ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                        </div>
                                        <div className='password-validation-label'>
                                            {PasswordValidations.MAXIMUM_20_CHARACTERS}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className={password ? number ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                        </div>
                                        <div className='password-validation-label'>
                                            {PasswordValidations.MUST_INCLUDE_A_NUMERIC_DIGIT}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className={password ? specialCharacter ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                        </div>
                                        <div className='password-validation-label'>
                                            {PasswordValidations.MUST_INCLUDE_A_SPECIAL_CHARATER}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className={password ? upperCase ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                        </div>
                                        <div className='password-validation-label'>
                                            {PasswordValidations.MUST_INCLUDE_AN_UPPER_CASE_LETTER}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className={password ? 'password-item-success' : 'password-item'}>
                                        </div>
                                        <div className='password-validation-label'>
                                            {PasswordValidations.CAN_INCLUDE_ONE_OR_MORE_SPACES}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className={password ? firstCharacterSpace ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                        </div>
                                        <div className='password-validation-label'>
                                            {PasswordValidations.MUST_NOT_BEGIN_WITH_A_SPACE}
                                        </div>
                                    </div>
                                </div>
                                <div class='arrow-left' />
                                <div className='arrow-cover' />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    );
}
export default (Signup);





