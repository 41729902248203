import { GET_PREFERENCES_REQUEST, GET_PREFERENCES_SUCCESS, GET_PREFERENCES_FAILURE, SUBMIT_PREFERENCES_REQUEST, SUBMIT_PREFERENCES_SUCCESS, SUBMIT_PREFERENCES_FAILURE, RESET } from './preferencesConstants';
const initialState = {
    loading: false,
    data: [],
    error: null,
    success: false
};

function PreferencesReducer(state = initialState, action = {}) {

    if (action.type == GET_PREFERENCES_REQUEST || action.type == SUBMIT_PREFERENCES_REQUEST) {
        return {
            ...state,
            loading: true,
            error: null,
        };
    }
    else if (action.type == GET_PREFERENCES_SUCCESS || action.type == SUBMIT_PREFERENCES_SUCCESS) {
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null,
            success: true
        };
    }
    else if (action.type == GET_PREFERENCES_FAILURE || action.type == SUBMIT_PREFERENCES_FAILURE) {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
            success: false
        };
    }
    else if (action.type == RESET) {
        return {
            ...state,
            loading: false,
            error: null,
            success: false
        };
    }
    else {
        return state;
    }
}

export default PreferencesReducer;