import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            <Component {...props} {...rest} />
        )} />
    );
};

PublicRoute.propTypes = {
    component: PropTypes.object
};

export default PublicRoute;