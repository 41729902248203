import { ApiService } from '../../services/api.service';
import { GET_PREFERENCES_REQUEST, GET_PREFERENCES_SUCCESS, GET_PREFERENCES_FAILURE, SUBMIT_PREFERENCES_REQUEST, SUBMIT_PREFERENCES_SUCCESS, SUBMIT_PREFERENCES_FAILURE, RESET } from './preferencesConstants';
import { alertActions } from '../alert/alertActions';
import { showAlert } from '../alert/showAlert';
import Cookies from 'js-cookie';
import { RETURL_URL, SAML_RESPONSE, FIRST_NAME, LAST_NAME } from '../../constants/constants'
// Get preferences
export function getPreferences() {
    return dispatch => {
        dispatch(getPreferencesRequested());
        ApiService.callGetPreferences().then(res => {
            if (res.data.status == 1) {
                dispatch(getPreferencesSuccess(res.data));
            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));
                dispatch(getPreferencesFailure(res.data));
            }
        })
            .catch(err => {
                dispatch(getPreferencesFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

const getPreferencesRequested = () => ({
    type: GET_PREFERENCES_REQUEST
});

const getPreferencesSuccess = data => ({
    type: GET_PREFERENCES_SUCCESS,
    payload: {
        ...data
    }
});
const getPreferencesFailure = error => ({
    type: GET_PREFERENCES_FAILURE,
    payload: {
        error
    }
});

// Submit preferences
export function submitPreferences(data) {
    return dispatch => {
        dispatch(submitPreferencesRequested());
        ApiService.callSubmitPreferences(data).then(res => {
            if (res.data.status == 1) {
                dispatch(submitPreferencesSuccess(res.data));
                dispatch(alertActions.success(res.data.message));
                // Redirect back to StudentBridge product
                if (Cookies.get(RETURL_URL) != null) {
                    var samlResponse = ''
                    var firstName = ''
                    var lastName = ''
                    if (localStorage.getItem(SAML_RESPONSE)) {
                        samlResponse = localStorage.getItem(SAML_RESPONSE);
                    }
                    if (Cookies.get(FIRST_NAME)) {
                        firstName = Cookies.get(FIRST_NAME)
                    }
                    if (Cookies.get(LAST_NAME)) {
                        lastName = Cookies.get(LAST_NAME)
                    }
                    window.open(Cookies.get(RETURL_URL) + '/?' + SAML_RESPONSE + '=' + samlResponse + '&' + FIRST_NAME + '=' + firstName + '&' + LAST_NAME + '=' + lastName, '_self');
                }
            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));
                dispatch(submitPreferencesFailure(res.data));
            }
        })
            .catch(err => {
                dispatch(submitPreferencesFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

const submitPreferencesRequested = () => ({
    type: SUBMIT_PREFERENCES_REQUEST
});

const submitPreferencesSuccess = data => ({
    type: SUBMIT_PREFERENCES_SUCCESS,
    payload: {
        ...data
    }
});

const submitPreferencesFailure = error => ({
    type: SUBMIT_PREFERENCES_FAILURE,
    payload: {
        error
    }
});

// Reset
export function getPreferencesReset() {
    return dispatch => {
        dispatch(reset());
    };
}

const reset = () => ({
    type: RESET
});
