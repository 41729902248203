
export const RESEND_CONFIRMATION_EMAIL_REQUEST = 'RESEND_CONFIRMATION_EMAIL_REQUEST';
export const RESEND_CONFIRMATION_EMAIL_SUCCESS = 'RESEND_CONFIRMATION_EMAIL_SUCCESS';
export const RESEND_CONFIRMATION_EMAIL_FAILURE = 'RESEND_CONFIRMATION_EMAIL_FAILURE';
export const ViewConstants = {
    TITLE: 'Please confirm your email!',
    VERIFY_EMAIL_CONFIRM_MESSAGE: 'To complete signup, please confirm your email address. We sent a confirmation link to ',
    CONFIRM_EMAIL_QUESTION: 'Don’t see a confirmation email?',
    CONFIRM_EMAIL_HELPER_TEXT: 'Try checking your spam folder or',
    RESEND_EMAIL: 'Resend email',
}