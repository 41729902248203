import { createStore, applyMiddleware, compose } from 'redux';
//Redux Thunk need to be added as a middleware
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import createRootReducer from '../app/reducers';

const loggerMiddleware = createLogger();
export const history = createBrowserHistory();
// Configuring the Store. PreloadState is the initial State.
export function configureStore(preloadedState) {

    const store = createStore(
        createRootReducer(history),
        preloadedState,
        //Apply the middleware using the Redux's provided applymiddleware function
        compose(
            applyMiddleware(
                thunkMiddleware,
                loggerMiddleware,
                routerMiddleware(history),
            )
        )

    );
    const action = type => store.dispatch({ type });
    return { action, store };
}