import React, { useState, useEffect, useContext } from 'react';
import { getPreferences, submitPreferences } from './preferencesActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Loader from '../../components/loader/loader';
import { ViewConstants } from './preferencesConstants';
import SideBar from '../../components/sideBar/sideBar';
import { alertActions } from '../alert/alertActions';
import AppContext from '../../components/appContext/appContext';


const Preferences = () => {

    const myContext = useContext(AppContext);

    const [submitPreferencesInputs, setSubmitPreferencesInputs] = useState({
        questionId: null,
        answer: null
    });

    const response = useSelector(state => state.preferencesData.data);
    const [questionsList, setQuestionsList] = useState([]);
    const [questionIndex] = useState(0);
    const [answerArray, setAnswerArray] = useState([])
    const loading = useSelector(state => state.preferencesData.loading);
    const success = useSelector(state => state.preferencesData.success);

    const [submit, setSubmit] = useState(false);

    const dispatch = useDispatch();

    // Get preferences questions api

    useEffect(() => {
        dispatch(getPreferences({}));
    }, []);

    // Handle preferences questions api response

    useEffect(() => {
        if (!submit) {
            let questions = response.data
            setQuestionsList(questions)
            setSubmitPreferencesInputs(({ ...submitPreferencesInputs, questionId: questions && questions.length > 0 && questions[questionIndex].questionId }
            ));
        }
        else {
            setSubmit(false)
        }
    }, [response]);

    // Select preference handling

    const preferenceSelected = (index) => {
        if (!answerArray.includes(index)) {
            setAnswerArray([...answerArray, index]);
        }
        else {
            setAnswerArray(answerArray.filter(item => item != index));
        }
    };

    // Submit preferences data handling

    const handleSavePreferences = (e) => {
        if (answerArray.length > 0) {
            const answersString = '{' + answerArray.toString() + '}'
            setSubmitPreferencesInputs(({ ...submitPreferencesInputs, answer: answersString }
            ));
            setSubmit(true)
        }
        else {
            dispatch(alertActions.error(ViewConstants.PREFERENCES_EMPTY_MESSAGE));
        }
    }

    // Submit preferences api

    useEffect(() => {
        if (submit) {
            dispatch(submitPreferences(submitPreferencesInputs));
        }
    }, [submit]);

    return (
        <div>
            <div className='main-top-private' />
            <div className="main-box-large">
                {(!loading || (questionsList && questionsList.length > 0)) && <SideBar preferences={true} />}
                <div className={'box-container-large ' + (loading ? 'border-radius' : 'no-border-radius')}>
                    {loading && <Loader />}
                    <div className={'main-header main-header-large ' + (!success ? 'main-header-bottom-error' : 'home-header-bottom')}>
                        {questionsList && questionsList.length > 0 &&
                            questionsList[questionIndex].question
                        }
                    </div>
                    {questionsList && questionsList.length > 0 && questionsList[questionIndex].questionType == 'Multiple selection' &&
                        <div className='sub-header-normal input-bottom-pad'>
                            <div className='sub-header-normal'>
                                {ViewConstants.SUB_TITLE}
                            </div>
                        </div>
                    }
                    {questionsList && questionsList.length > 0 &&
                        <div className='answer-holder'>
                            <div className='solid-line-large line-margin' />
                            <div className='line-button-bottom'>
                                <div className={questionsList[questionIndex].questionType == 'Yes/No' ? 'bubble-container-center' : 'bubble-container-start'}>
                                    {questionsList[questionIndex].questionOptions.map((item, index) => {
                                        return (
                                            <div className='question-right-margin question-bottom-margin'>
                                                <Button className={answerArray && answerArray.includes(index) ? 'bubble-selected' : 'bubble'} onClick={() => preferenceSelected(index)}>
                                                    {item}
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className='solid-line-large line-button-bottom' />
                        </div>
                    }
                    {questionsList && questionsList.length > 0 &&
                        <Button className='primary-btn input-bottom-pad' style={myContext.buttonStyle} onClick={handleSavePreferences}>
                            {ViewConstants.SAVE_MY_PREFERENCES}
                        </Button>
                    }
                </div>
            </div>
        </div >
    );

}
export default (Preferences);


