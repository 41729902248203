import React, { useState, useEffect, useContext } from 'react';
import { ViewConstants } from './resetPasswordConstants';
import { resetPassword, resetPasswordReset } from './resetPasswordActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Loader from '../../components/loader/loader';
import { history } from '../../../helpers/history';
import { SignInForm, PasswordValidations, UserType } from '../../constants/constants';
import { hasMinCharacters, hasMaxCharacters, hasNumber, hasSpecial, hasUpperCase, hasFirstCharacterSpace } from '../../../helpers/validations';
import AppContext from '../../components/appContext/appContext';

const ResetPassword = () => {

    const myContext = useContext(AppContext);

    const [inputs, setInputs] = useState({
        resetToken: '',
        newPassword: '',
        userType: ''
    });

    const [emailId, setEmailId] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showError, setShowError] = useState(false);

    const [minimumCharactors, setMinimumCharactersError] = useState(true);
    const [maximumCharaters, setMaximumCharatersError] = useState(false);
    const [number, setNumberError] = useState(true);
    const [specialCharacter, setSpecialCharacterError] = useState(true);
    const [upperCase, setUpperCaseError] = useState(true);
    const [firstCharacterSpace, setFirstCharacterSpaceError] = useState(false);

    const [confirmPasswordMinimumCharactors, setConfirmPasswordMinimumCharactersError] = useState(true);
    const [confirmPasswordMaximumCharaters, setConfirmPasswordMaximumCharatersError] = useState(false);
    const [confirmPasswordNumber, setConfirmPasswordNumberError] = useState(true);
    const [confirmPasswordSpecialCharacter, setConfirmPasswordSpecialCharacterError] = useState(true);
    const [confirmPasswordUpperCase, setConfirmPasswordUpperCaseError] = useState(true);
    const [confirmPasswordFirstCharacterSpace, setConfirmPasswordFirstCharacterSpaceError] = useState(false);

    const [passwordError, setPasswordError] = useState(false);

    const [newPasswordError, setNewPasswordError] = useState(false);

    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const [errorMessage, setErrorMessage] = useState(ViewConstants.GENERAL_ERROR_MESSAGE);

    const { newPassword, resetToken } = inputs;

    const loading = useSelector(state => state.resetPasswordData.loading);
    const success = useSelector(state => state.resetPasswordData.success);


    const dispatch = useDispatch();

    // Input views action

    const handleChange = (e) => {

        const { name, value } = e.target;
        if (name == 'confirmNewPassword') {
            setConfirmNewPassword(value)
        }
        else {
            setInputs(({ ...inputs, [name]: value }));
        }
        if (name == 'newPassword') {
            if (hasMinCharacters(value).status) {
                setMinimumCharactersError(false)
            }
            else {
                setMinimumCharactersError(true)
            }
            if (hasMaxCharacters(value).status) {
                setMaximumCharatersError(false)
            }
            else {
                setMaximumCharatersError(true)
            }
            if (hasNumber(value).status) {
                setNumberError(false)
            }
            else {
                setNumberError(true)
            }
            if (hasSpecial(value).status) {
                setSpecialCharacterError(false)
            }
            else {
                setSpecialCharacterError(true)
            }
            if (hasUpperCase(value).status) {
                setUpperCaseError(false)
            }
            else {
                setUpperCaseError(true)
            }
            if (hasFirstCharacterSpace(value).status) {
                setFirstCharacterSpaceError(false)
            }
            else {
                setFirstCharacterSpaceError(true)
            }
        }
        else if (name == 'confirmNewPassword') {
            if (hasMinCharacters(value).status) {
                setConfirmPasswordMinimumCharactersError(false)
            }
            else {
                setConfirmPasswordMinimumCharactersError(true)
            }
            if (hasMaxCharacters(value).status) {
                setConfirmPasswordMaximumCharatersError(false)
            }
            else {
                setConfirmPasswordMaximumCharatersError(true)
            }
            if (hasNumber(value).status) {
                setConfirmPasswordNumberError(false)
            }
            else {
                setConfirmPasswordNumberError(true)
            }
            if (hasSpecial(value).status) {
                setConfirmPasswordSpecialCharacterError(false)
            }
            else {
                setConfirmPasswordSpecialCharacterError(true)
            }
            if (hasUpperCase(value).status) {
                setConfirmPasswordUpperCaseError(false)
            }
            else {
                setConfirmPasswordUpperCaseError(true)
            }
            if (hasFirstCharacterSpace(value).status) {
                setConfirmPasswordFirstCharacterSpaceError(false)
            }
            else {
                setConfirmPasswordFirstCharacterSpaceError(true)
            }
        }
    }

    // Password validation views handling

    const handlePasswordFocus = (e) => {
        const { name } = e.target;
        setPasswordError(true)
        if (name == 'newPassword') {
            setNewPasswordError(true)
            setConfirmPasswordError(false)
        }
        else {
            setConfirmPasswordError(true)
            setNewPasswordError(false)
        }
    }

    const handlePasswordBlur = (e) => {
        const { name } = e.target;
        setPasswordError(false)
        if (name == 'newPassword') {
            setNewPasswordError(false)
        }
        else {
            setConfirmPasswordError(false)
        }
    }

    // Password validation error handling

    const checkPasswordError = () => {
        if (minimumCharactors || maximumCharaters || number || specialCharacter || upperCase || firstCharacterSpace) {
            return true
        }
        return false
    }
    const checkConfirmPasswordError = () => {
        if (confirmPasswordMinimumCharactors || confirmPasswordMaximumCharaters || confirmPasswordNumber || confirmPasswordSpecialCharacter || confirmPasswordUpperCase || confirmPasswordFirstCharacterSpace) {
            return true
        }
        return false
    }

    // Reset password api

    const handleResetPassword = (e) => {
        if (newPassword && confirmNewPassword) {
            if (newPassword != confirmNewPassword) {
                setShowError(true)
                setErrorMessage(ViewConstants.PASSWORD_MISMATCH_ERROR_MESSAGE)
            }
            else if (checkPasswordError()) {
                setErrorMessage(ViewConstants.GENERAL_ERROR_MESSAGE)
                setShowError(true)
                setPasswordError(true)
                setNewPasswordError(true)
            }
            else if (checkConfirmPasswordError()) {
                setErrorMessage(ViewConstants.GENERAL_ERROR_MESSAGE)
                setShowError(true)
                setPasswordError(true)
                setConfirmPasswordError(true)
            }
            else {
                setShowError(false)
                setPasswordError(false)
                setNewPasswordError(false)
                setConfirmPasswordError(false)
                setErrorMessage(ViewConstants.GENERAL_ERROR_MESSAGE)
                dispatch(resetPassword(inputs));
            }
        }
        else {
            setErrorMessage(ViewConstants.GENERAL_ERROR_MESSAGE)
            setPasswordError(false)
            setShowError(true)
            setNewPasswordError(false)
            setConfirmPasswordError(false)
        }
    }

    // Login navigation

    const handleLogin = (e) => {
        dispatch(resetPasswordReset());
        if (inputs.userType == UserType.ADMIN) {
            history.push('/admin/login')
        }
        else {
            history.push('/login')
        }
    }

    // Get reset token form url

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const resetTokenValue = query.get('resetToken')
        const email = query.get('emailId')
        const userType = query.get('userType')
        if (emailId != null) {
            setEmailId(email)
        }
        if (resetToken != null) {
            setInputs(({ ...inputs, resetToken: resetTokenValue, userType: userType }));
        }
    }, []);

    return (
        <div>
            <div className='main-top' />
            <div className="main-box">
                <div className='box-container'>
                    {loading && <Loader />}
                    <div className={'main-header ' + (showError ? 'main-header-bottom-error' : 'main-header-bottom')}>
                        {success ? ViewConstants.SUCCESS_TITLE : ViewConstants.CHOOSE_NEW_PASSWORD}
                    </div>
                    {showError &&
                        <div className='error-label'>
                            {errorMessage}
                        </div>
                    }
                    {!success &&
                        <div className='sub-header-bold'>
                            {emailId}
                        </div>}
                    {!success ? <div className='column'>
                        <div className='input-fld-container input-bottom-pad'>
                            <input
                                type="password"
                                name='newPassword'
                                className={'input-fld input-fld-width input-fld-border ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                                onChange={handleChange}
                                onFocus={handlePasswordFocus}
                                onBlur={handlePasswordBlur}
                                value={newPassword}
                                required />
                            <span class='floating-label'>{SignInForm.PASSWORD}</span>
                        </div>
                        <div className='input-fld-container input-bottom-pad'>
                            <input
                                type='password'
                                name='confirmNewPassword'
                                className={'input-fld input-fld-width input-fld-border ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                                onChange={handleChange}
                                onFocus={handlePasswordFocus}
                                onBlur={handlePasswordBlur}
                                value={confirmNewPassword}
                                required />
                            <span class='floating-label'>{ViewConstants.CONFIRM_NEW_PASSWORD}</span>
                        </div>
                        {passwordError &&
                            <div className={'password-container password-reset-margin-left ' + (newPasswordError ? (showError ? 'password-container-reset-new-top-error' : 'password-container-reset-new-top') : (confirmPasswordError && (showError ? 'password-container-reset-confirm-top-error' : 'password-container-reset-confirm-top')))}>
                                <div>
                                    <div className='reset-password-box'>
                                        <div className='password-header'>
                                            {PasswordValidations.PASSWORD_VAIDATION_TITLE}
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? minimumCharactors ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordMinimumCharactors ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MINIMUM_8_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? maximumCharaters ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordMaximumCharaters ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MAXIMUM_20_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? number ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordNumber ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MUST_INCLUDE_A_NUMERIC_DIGIT}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? specialCharacter ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordSpecialCharacter ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MUST_INCLUDE_A_SPECIAL_CHARATER}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? upperCase ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordUpperCase ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MUST_INCLUDE_AN_UPPER_CASE_LETTER}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? 'password-item-success' : 'password-item' : confirmNewPassword ? 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.CAN_INCLUDE_ONE_OR_MORE_SPACES}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? firstCharacterSpace ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordFirstCharacterSpace ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MUST_NOT_BEGIN_WITH_A_SPACE}
                                            </div>
                                        </div>
                                    </div>
                                    <div class='reset-arrow-left' />
                                    <div class='reset-arrow-cover' />
                                </div>
                            </div>
                        }
                        {/* <div className='link-btn-padding input-bottom-pad'> */}
                        <Button className='primary-btn primary-field-top' style={myContext.buttonStyle} onClick={handleResetPassword}>
                            {ViewConstants.RESET_PASSWORD}
                        </Button>
                        {/* </div> */}
                    </div> :
                        <div className='confirm-holder'>
                            <div className='sub-header-confirm sub-header-bottom'>
                                <div className='sub-header-normal-confirm'>
                                    {ViewConstants.RESET_PASSWORD_SUCCESS_MESSAGE}
                                </div>
                            </div>
                            <div className='solid-line'>
                            </div>
                        </div>
                    }
                    {/* {success ? */}
                    <div className={'link-btn blue-link-btn lnk-btn-bottom ' + (!success && 'link-btn-top')} onClick={handleLogin}>
                        {ViewConstants.BACK_TO_LOGIN}
                    </div>
                    {/* :
                        <div className='btn-bottom'>
                        </div> */}
                    {/* } */}
                </div>
            </div>
        </div >
    );

}
export default (ResetPassword);


