// const isPasswordValid = (password) => {


//     const value = password;

export function hasMinCharacters(password) {
    var isFormValid = true;
    var errors = ""
    const passwordValue = password;
    const hasMinCharactersWith = value => {
        return (value.length >= 8);
    }
    if (!hasMinCharactersWith(passwordValue)) {
        isFormValid = false;
        // errors = "Password length should be mininum of 8 characters";
        errors = "mininumCount";
    }
    return { status: isFormValid, error: errors };
}

export function hasMaxCharacters(password) {
    var isFormValid = true;
    var errors = ""
    const passwordValue = password;
    const hasMaxCharactersWith = value => {
        return (value.length > 20);
    }
    if (hasMaxCharactersWith(passwordValue)) {
        isFormValid = false;
        // errors = "Password length should be maximum of 20 characters";
        errors = "maximumCount";
    }
    return { status: isFormValid, error: errors };
}

export function hasNumber(password) {
    var isFormValid = true;
    var errors = ""
    const passwordValue = password;
    const hasNumberWith = value => {
        return new RegExp(/[0-9]/).test(value);
    }
    if (!hasNumberWith(passwordValue)) {
        isFormValid = false;
        // errors = "Password should contain atleast 1 number";
        errors = "number";
    }
    return { status: isFormValid, error: errors };
}

export function hasSpecial(password) {
    var isFormValid = true;
    var errors = ""
    const passwordValue = password;
    const hasSpecialWith = value => {
        // return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
        return new RegExp(/[`~!#@$%^&*)(+=.,<>?/;:{}|'"[_-]/).test(value);
    }

    if (!hasSpecialWith(passwordValue)) {
        isFormValid = false;
        // errors = "Password should contain atleast 1 special character";
        errors = "specialCharacter";
    }
    return { status: isFormValid, error: errors };
}

export function hasUpperCase(password) {
    var isFormValid = true;
    var errors = ""
    const passwordValue = password;
    const hasUpperCaseWith = value => {
        return new RegExp(/[A-Z]/).test(value);
    }
    if (!hasUpperCaseWith(passwordValue)) {
        isFormValid = false;
        // errors = "Password should contain atleast 1 uppercase letter";
        errors = "uppercase";
    }
    return { status: isFormValid, error: errors };
}

export function hasFirstCharacterSpace(password) {
    var isFormValid = true;
    var errors = ""
    const passwordValue = password;
    const hasFirstCharacterSpaceWith = value => {
        return value.indexOf(' ')
    }

    if (hasFirstCharacterSpaceWith(passwordValue) == 0) {
        isFormValid = false;
        // errors = "Password should not begin with a space";
        errors = "space";
    }
    return { status: isFormValid, error: errors };
}


// }
// 
// const Validations = { isPasswordValid };
// export default Validations;





