import React, { useEffect, useState } from 'react';

// Import the Routes component, which contains our Route setup
import Routes from './routes';

// Provider component is a react-redux component for setting up the Redux Store
import { Provider, useDispatch, useSelector } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';

// Import the ConfigureStore that holds the initial Configuration
import { configureStore, history } from './store/configure.store';

// css
import './lib/reactifyCss';
import { BrandCheck } from './app/constants/constants';
import AppContext from './app/components/appContext/appContext';
import { universityDesign } from './app/general/generalActions';

// Create a Store from the Configuration, we can pass a Initial State here
const { store } = configureStore();

const App = () => {

  const [privacyPolicy, setPrivacyPolicy] = useState('https://www.studentbridge.com/privacy-policy');
  const [buttonStyle, setButtonStyle] = useState({
    color: '',
    background: ''
  });

  const schoolSettings = {
    buttonStyle,
    privacyPolicy
  };

  const dispatch = useDispatch();
  const universityData = useSelector(state => state.generalData.data);

  useEffect(() => {
    dispatch(universityDesign())
  }, []);

  useEffect(() => {
    if (universityData.data) {
      var finalData = universityData.data[0]
      var body = document.getElementById("body");
      var logo = document.getElementById("logo");
      var favicon = document.getElementById("favicon");
      var footerText = document.getElementById("footer-text");

      favicon.href = finalData.favicon
      body.style.backgroundImage = `url(${finalData.backgroundImage})`;
      if (window.location.origin == BrandCheck.STUDENT_BRIDGE) {
        body.style.backgroundPosition = 'bottom';
      }
      else {
        body.style.backgroundPosition = 'center';
      }
      logo.style.content = `url(${finalData.logo})`;
      footerText.textContent = finalData.copyrightText;
      footerText.style.color = finalData.footerTextColor

      setPrivacyPolicy(finalData.privacyPolicyLink)
      setButtonStyle({
        color: finalData.buttonTextColor,
        background: finalData.buttonBackgroundColor
      })
    }

  }, [universityData]);

  return (
    <AppContext.Provider value={schoolSettings} store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </AppContext.Provider>
  );
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

export default AppWrapper;


