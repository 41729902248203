export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const GET_AUTH_LOGIN_TOKEN_REQUEST = 'GET_AUTH_LOGIN_TOKEN_REQUEST';
export const GET_AUTH_LOGIN_TOKEN_SUCCESS = 'GET_AUTH_LOGIN_TOKEN_SUCCESS';
export const GET_AUTH_LOGIN_TOKEN_FAILURE = 'GET_AUTH_LOGIN_TOKEN_FAILURE';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

export const ViewConstants = {
    LOGIN_TITLE: 'Login to your account',
    PASSWORD_OR_MAIL_ERROR: 'Incorrect email address and / or password.',
    SIGN_UP_HELPER_TEXT: `Don't have an account?`,
    LOGIN: 'LOGIN',
    CONTINUE: 'CONTINUE',
    SIGN_UP: 'Sign up',
    REMEMBER_ME: 'Remember me',
    LOGIN_GENERAL_ERROR_MESSAGE: 'Please double check your username and/or password and try again.',
}