import { ApiService } from '../../services/api.service';
import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE, RESET } from './changePasswordConstants';
import { alertActions } from '../alert/alertActions';
import { showAlert } from '../alert/showAlert';
import Cookies from 'js-cookie';
import { SignInForm } from '../../constants/constants';



export function changePassword(data) {
    return dispatch => {
        dispatch(changePasswordRequested());
        ApiService.callChangePassword(data).then(res => {
            if (res.data.status == 1) {
                dispatch(changePasswordSuccess(res.data));
                if (Cookies.get(SignInForm.REMEMBER_ME)) {
                    Cookies.set(SignInForm.PASSWORD, data.newPassword)
                }
                dispatch(alertActions.success(res.data.message));
            }
            else if (res.data.status == 0) {
                dispatch(changePasswordFailure(res.data));
                dispatch(alertActions.error(res.data.message));
            }
        })
            .catch(err => {
                dispatch(changePasswordFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

export function changePasswordReset() {
    return dispatch => {
        dispatch(reset());
    };
}

const changePasswordRequested = () => ({
    type: CHANGE_PASSWORD_REQUEST
});

const changePasswordSuccess = data => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: {
        ...data
    }
});

const changePasswordFailure = error => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: {
        error
    }
});

const reset = () => ({
    type: RESET
});
