import { UNIVERSITY_DESIGN_REQUEST, UNIVERSITY_DESIGN_SUCCESS, UNIVERSITY_DESIGN_FAILURE, RESET } from './generalConstants';
const initialState = {
    loading: false,
    data: [],
    error: null,
    success: false
};

function generalReducer(state = initialState, action = {}) {

    switch (action.type) {

        case UNIVERSITY_DESIGN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UNIVERSITY_DESIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
                success: true
            };
        case UNIVERSITY_DESIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                success: false
            };

        case RESET:
            return {
                ...state,
                loading: false,
                error: null,
                success: false
            };
        default:
            return state;
    }
}

export default generalReducer;