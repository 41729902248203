import React, { useState, useContext, useEffect } from 'react';
import { changePassword, changePasswordReset } from './changePasswordActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Loader from '../../components/loader/loader';
import Cookies from 'js-cookie';
import { history } from '../../../helpers/history';
import { SignInForm, PasswordValidations, UserType } from '../../constants/constants';
import { ViewConstants } from './changePasswordConstants';
import { hasMinCharacters, hasMaxCharacters, hasNumber, hasSpecial, hasUpperCase, hasFirstCharacterSpace } from '../../../helpers/validations';
import SideBar from '../../components/sideBar/sideBar';
import AppContext from '../../components/appContext/appContext';

const ChangePassword = (props) => {

    const myContext = useContext(AppContext);

    const [inputs, setInputs] = useState({
        emailId: Cookies.get(SignInForm.EMAIL_ID),
        oldPassword: '',
        newPassword: '',
        userType: props.location.state.admin ? UserType.ADMIN : UserType.STUDENT
    });

    const { emailId, oldPassword, newPassword } = inputs;
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(ViewConstants.GENERAL_ERROR_MESSAGE);

    const [minimumCharactors, setMinimumCharactersError] = useState(true);
    const [maximumCharaters, setMaximumCharatersError] = useState(false);
    const [number, setNumberError] = useState(true);
    const [specialCharacter, setSpecialCharacterError] = useState(true);
    const [upperCase, setUpperCaseError] = useState(true);
    const [firstCharacterSpace, setFirstCharacterSpaceError] = useState(false);

    const [confirmPasswordMinimumCharactors, setConfirmPasswordMinimumCharactersError] = useState(true);
    const [confirmPasswordMaximumCharaters, setConfirmPasswordMaximumCharatersError] = useState(false);
    const [confirmPasswordNumber, setConfirmPasswordNumberError] = useState(true);
    const [confirmPasswordSpecialCharacter, setConfirmPasswordSpecialCharacterError] = useState(true);
    const [confirmPasswordUpperCase, setConfirmPasswordUpperCaseError] = useState(true);
    const [confirmPasswordFirstCharacterSpace, setConfirmPasswordFirstCharacterSpaceError] = useState(false);

    const loading = useSelector(state => state.changePasswordData.loading);

    const success = useSelector(state => state.changePasswordData.success);

    const dispatch = useDispatch();

    // Input views action

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'confirmNewPassword') {
            setConfirmNewPassword(value)
        }
        else {
            setInputs(({ ...inputs, [name]: value }));
        }
        if (name == 'newPassword') {
            if (hasMinCharacters(value).status) {
                setMinimumCharactersError(false)
            }
            else {
                setMinimumCharactersError(true)
            }
            if (hasMaxCharacters(value).status) {
                setMaximumCharatersError(false)
            }
            else {
                setMaximumCharatersError(true)
            }
            if (hasNumber(value).status) {
                setNumberError(false)
            }
            else {
                setNumberError(true)
            }
            if (hasSpecial(value).status) {
                setSpecialCharacterError(false)
            }
            else {
                setSpecialCharacterError(true)
            }
            if (hasUpperCase(value).status) {
                setUpperCaseError(false)
            }
            else {
                setUpperCaseError(true)
            }
            if (hasFirstCharacterSpace(value).status) {
                setFirstCharacterSpaceError(false)
            }
            else {
                setFirstCharacterSpaceError(true)
            }
        }
        else if (name == 'confirmNewPassword') {
            if (hasMinCharacters(value).status) {
                setConfirmPasswordMinimumCharactersError(false)
            }
            else {
                setConfirmPasswordMinimumCharactersError(true)
            }
            if (hasMaxCharacters(value).status) {
                setConfirmPasswordMaximumCharatersError(false)
            }
            else {
                setConfirmPasswordMaximumCharatersError(true)
            }
            if (hasNumber(value).status) {
                setConfirmPasswordNumberError(false)
            }
            else {
                setConfirmPasswordNumberError(true)
            }
            if (hasSpecial(value).status) {
                setConfirmPasswordSpecialCharacterError(false)
            }
            else {
                setConfirmPasswordSpecialCharacterError(true)
            }
            if (hasUpperCase(value).status) {
                setConfirmPasswordUpperCaseError(false)
            }
            else {
                setConfirmPasswordUpperCaseError(true)
            }
            if (hasFirstCharacterSpace(value).status) {
                setConfirmPasswordFirstCharacterSpaceError(false)
            }
            else {
                setConfirmPasswordFirstCharacterSpaceError(true)
            }
        }
    }

    // Password validation error handling

    const checkNewPasswordError = () => {
        if (minimumCharactors || maximumCharaters || number || specialCharacter || upperCase || firstCharacterSpace) {
            return true
        }
        return false
    }

    const checkConfirmPasswordError = () => {
        if (confirmPasswordMinimumCharactors || confirmPasswordMaximumCharaters || confirmPasswordNumber || confirmPasswordSpecialCharacter || confirmPasswordUpperCase || confirmPasswordFirstCharacterSpace) {
            return true
        }
        return false
    }

    // Password validation view handling

    const handlePasswordFocus = (e) => {

        const { name } = e.target;
        setPasswordError(true)
        if (name == 'newPassword') {
            setNewPasswordError(true)
            setConfirmPasswordError(false)
        }
        else {
            setConfirmPasswordError(true)
            setNewPasswordError(false)
        }

    }

    const handlePasswordBlur = (e) => {

        const { name } = e.target;
        setPasswordError(false)
        if (name == 'newPassword') {
            setNewPasswordError(false)
        }
        else {
            setConfirmPasswordError(false)
        }

    }

    const clearCredentialsFromStorage = () => {
        // Cookies.remove(SignInForm.EMAIL_ID);
    }

    // Cancel navigation

    const handleCancel = (e) => {
        if (props.location.state.admin == true) {
            history.push('/admin/cmsSites')
        }
        else {
            history.push('/preferences')
        }
    }

    // Save action

    const handleSave = (e) => {

        if (oldPassword && newPassword && confirmNewPassword) {
            if (newPassword != confirmNewPassword) {
                setShowError(true)
                setErrorMessage(ViewConstants.PASSWORD_MISMATCH_ERROR_MESSAGE)
            }
            else if (checkNewPasswordError()) {
                setShowError(true)
                setPasswordError(true)
                setNewPasswordError(true)
                setErrorMessage(ViewConstants.GENERAL_ERROR_MESSAGE)
            }
            else if (checkConfirmPasswordError()) {
                setShowError(true)
                setPasswordError(true)
                setConfirmPasswordError(true)
                setErrorMessage(ViewConstants.GENERAL_ERROR_MESSAGE)

            }
            else {
                setPasswordError(false)
                setShowError(false)
                setNewPasswordError(false)
                setConfirmPasswordError(false)
                dispatch(changePassword(inputs));
                setErrorMessage(ViewConstants.GENERAL_ERROR_MESSAGE)
            }
        }
        else {
            setShowError(true)
            setErrorMessage(ViewConstants.GENERAL_ERROR_MESSAGE)
        }
    }

    // Login navugation

    const handleLogin = (e) => {
        dispatch(changePasswordReset());
        clearCredentialsFromStorage();
        if (props.location.state.admin == true) {
            history.push('/admin/login')
        }
        else {
            history.push('/login')
        }
    }

    // Change password api

    useEffect(() => {
        dispatch(changePasswordReset());

    }, []);

    return (
        <div className='window-padding'>
            <div className='main-top-private' />
            <div className="main-box-change-password">
                <SideBar securityAndPassword={true} admin={props.location.state.admin} />
                <div className='box-container-change-paswrd'>
                    {loading && <Loader />}
                    <div className={'main-header ' + (showError ? 'main-header-bottom-error' : 'main-header-bottom')}>
                        {success ? ViewConstants.SUCCESS_TITLE : ViewConstants.TITLE}
                    </div>
                    {showError &&
                        <div className='error-label'>
                            {errorMessage}
                        </div>
                    }
                    {!success ? <div className='column'>
                        <div className='input-fld-container input-bottom-pad'>
                            <input
                                type='password'
                                name='oldPassword'
                                className={'input-fld input-fld-width input-fld-border ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                                onChange={handleChange}
                                value={oldPassword}
                                required />
                            <span class='floating-label'>{ViewConstants.OLD_PASSWORD}</span>
                        </div>
                        <div className='input-fld-container input-bottom-pad'>
                            <input
                                type='password'
                                name='newPassword'
                                className={'input-fld input-fld-width input-fld-border ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                                onChange={handleChange}
                                onFocus={handlePasswordFocus}
                                onBlur={handlePasswordBlur}
                                value={newPassword}
                                required />
                            <span class='floating-label'>{ViewConstants.NEW_PASSWORD}</span>
                        </div>
                        <div className='input-fld-container input-bottom-pad'>
                            <input
                                type='password'
                                name='confirmNewPassword'
                                className={'input-fld input-fld-width input-fld-border ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                                onChange={handleChange}
                                onFocus={handlePasswordFocus}
                                onBlur={handlePasswordBlur}
                                value={confirmNewPassword}
                                required />
                            <span class='floating-label'>{ViewConstants.CONFIRM_NEW_PASSWORD}</span>
                        </div>
                        {passwordError &&
                            <div className={'change-password-container password-change-margin-left ' + (newPasswordError ? (showError ? 'password-container-change-new-top-error' : 'password-container-change-new-top') : (confirmPasswordError && (showError ? 'password-container-change-confirm-top-error' : 'password-container-change-confirm-top')))}>

                                <div>
                                    <div className='change-password-box'>
                                        <div className='password-header'>
                                            {PasswordValidations.PASSWORD_VAIDATION_TITLE}
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? minimumCharactors ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordMinimumCharactors ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MINIMUM_8_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? maximumCharaters ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordMaximumCharaters ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MAXIMUM_20_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? number ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordNumber ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MUST_INCLUDE_A_NUMERIC_DIGIT}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? specialCharacter ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordSpecialCharacter ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MUST_INCLUDE_A_SPECIAL_CHARATER}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? upperCase ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordUpperCase ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MUST_INCLUDE_AN_UPPER_CASE_LETTER}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? 'password-item-success' : 'password-item' : confirmNewPassword ? 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.CAN_INCLUDE_ONE_OR_MORE_SPACES}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={newPasswordError ? newPassword ? firstCharacterSpace ? 'password-item-error' : 'password-item-success' : 'password-item' : confirmNewPassword ? confirmPasswordFirstCharacterSpace ? 'password-item-error' : 'password-item-success' : 'password-item'}>
                                            </div>
                                            <div className='password-validation-label'>
                                                {PasswordValidations.MUST_NOT_BEGIN_WITH_A_SPACE}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='change-arrow-left' />
                                    <div className='change-arrow-cover' />
                                </div>
                            </div>
                        }
                        <div className='btn-align field-btn-top'>
                            <Button className='secondary-btn half-btn-width name-margin-right half-btn-left' onClick={handleCancel}>
                                {ViewConstants.CANCEL}
                            </Button>
                            <Button className='primary-btn half-btn-width name-margin-left half-btn-right ' style={myContext.buttonStyle} onClick={handleSave}>
                                {ViewConstants.SAVE}
                            </Button>

                        </div>
                    </div> :
                        <div className='confirm-holder'>
                            <div className='sub-header-confirm sub-header-bottom'>
                                <div className='sub-header-normal-confirm'>
                                    {ViewConstants.CHANGE_PASSWORD_SUCCESS_MESSAGE}
                                </div>
                                <div className='sub-header-bold-confirm'>
                                    {emailId}
                                </div>
                            </div>
                            <div className='solid-line'>
                            </div>
                        </div>
                    }
                    {success ?
                        <div className={'link-btn blue-link-btn lnk-btn-bottom ' + (!success && 'link-btn-top')} onClick={handleLogin}>
                            {ViewConstants.BACK_TO_LOGIN}
                        </div> :
                        <div className='btn-bottom'>
                        </div>
                    }
                </div>
            </div>
        </div >


    );

}
export default (ChangePassword);


