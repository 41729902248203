import React, { useState, useEffect, useContext } from 'react';
import { login, authLogin } from './loginActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Loader from '../../components/loader/loader';
import validator from 'validator';
import Cookies from 'js-cookie';
import { history } from '../../../helpers/history';
import { SignInForm, LOST_PASSWORD, OR, OAUTH_SECRET, UserType, RETURL_URL } from '../../constants/constants';
import { ViewConstants } from './loginConstants';
import { Google, Facebook, Microsoft, Twitter } from '../../components/OAuth2/OAuth2';
import AppContext from '../../components/appContext/appContext';

const Login = () => {

    const myContext = useContext(AppContext);

    const [inputs, setInputs] = useState({
        emailId: Cookies.get(SignInForm.REMEMBER_ME) ? Cookies.get(SignInForm.EMAIL_ID) : '',
        password: Cookies.get(SignInForm.REMEMBER_ME) ? Cookies.get(SignInForm.PASSWORD) : '',
        userType: UserType.STUDENT
    });

    const [showError, setShowError] = useState(false);

    const [rememberMe, setRememberMe] = useState(Cookies.get(SignInForm.PASSWORD) && Cookies.get(SignInForm.PASSWORD).length > 1);

    const { emailId, password } = inputs;

    const loading = useSelector(state => state.loginData.loading);

    const dispatch = useDispatch();

    // Input views action 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(({ ...inputs, [name]: value }));
    }

    // Remember me action

    const handleCheckbox = () => {
        setRememberMe(!rememberMe)
    }

    // Remember login details

    const saveCredentialsInStorage = () => {
        Cookies.set(SignInForm.EMAIL_ID, inputs.emailId)
        Cookies.set(SignInForm.PASSWORD, inputs.password)
        Cookies.set(SignInForm.REMEMBER_ME, rememberMe)
    }

    // Clear login data

    const clearCredentialsFromStorage = () => {
        Cookies.set(SignInForm.EMAIL_ID, inputs.emailId)
        Cookies.remove(SignInForm.PASSWORD);
        Cookies.remove(SignInForm.REMEMBER_ME)
    }

    // Login action

    const handleLogin = (e) => {
        if (rememberMe == true) {
            saveCredentialsInStorage();
        } else {
            clearCredentialsFromStorage();
        }
        if (emailId && password && validator.isEmail(emailId)) {
            setShowError(false)
            dispatch(login(inputs));
        }
        else {
            setShowError(true)
        }
    }

    // Forgot password navigation

    const handleForgotPassword = (e) => {
        if (emailId && validator.isEmail(emailId)) {
            Cookies.set(SignInForm.EMAIL_ID, inputs.emailId, { expires: 7 });
        }
        history.push(
            {
                pathname: '/forgotPassword',
                state: { admin: false }
            }
        )
    }
    // Sign up navigation

    const handleSignup = (e) => {
        history.push('/signup')
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        //To redirect back to StudentBridge product
        if (query.get(RETURL_URL) != null) {
            Cookies.set(RETURL_URL, query.get(RETURL_URL))
        }

        const oauth_token = query.get('oauth_token')
        const oauth_verifier = query.get('oauth_verifier')
        if (oauth_token && oauth_verifier) {
            const data = {
                oauth_token: oauth_token,
                oauth_verifier: oauth_verifier,
                oauth_secret: Cookies.get(OAUTH_SECRET),
            }
            // call final auth login api 
            dispatch(authLogin(data));
        }
    }, []);

    return (
        <div>
            <div className='main-top' />
            <div className="main-box">
                <div className='box-container'>
                    {loading && <Loader />}
                    <div className={'main-header ' + (showError ? 'main-header-bottom-error' : 'main-header-bottom')}>
                        {ViewConstants.LOGIN_TITLE}
                    </div>
                    {showError &&
                        <div className='error-label'>
                            {ViewConstants.LOGIN_GENERAL_ERROR_MESSAGE}
                        </div>
                    }
                    <div className='input-fld-container input-bottom-pad'>
                        <input
                            type="text"
                            name='emailId'
                            className={'input-fld input-fld-width ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                            onChange={handleChange}
                            defaultValue={emailId}
                            value={emailId}
                            required
                        />
                        <span class="floating-label">{SignInForm.ENTER_EMAIL}</span>
                    </div>
                    <div className='input-fld-container'>
                        <input
                            type="password"
                            name='password'
                            className={'input-fld input-fld-width input-fld-border ' + (showError ? 'input-fld-error' : 'input-fld-border')}
                            onChange={handleChange}
                            defaultValue={password}
                            value={password}
                            required />
                        <span class="floating-label">{SignInForm.PASSWORD}</span>
                    </div>
                    <div className='row-equal link-btn-padding checkbox-helper-container create-helper-margin-left'>
                        <div className='row-views'>
                            <input type="checkbox" name="rememberMe" value="rememberMe" class='large margin-right' checked={rememberMe == true} onChange={handleCheckbox} >
                            </input>
                            <div className='info-label'>
                                {ViewConstants.REMEMBER_ME}
                            </div>
                        </div>
                        <div className='link-btn left-align' onClick={handleForgotPassword}>
                            {LOST_PASSWORD}
                        </div>
                    </div>
                    <div className='link-btn-padding'>
                        <Button className='primary-btn' style={myContext.buttonStyle} onClick={handleLogin}>
                            {ViewConstants.LOGIN}
                        </Button>
                    </div>

                    <div className='row helper-padding'>
                        <div className='info-label margin-right'>
                            {ViewConstants.SIGN_UP_HELPER_TEXT}
                        </div>
                        <div className='link-btn' onClick={handleSignup}>
                            {ViewConstants.SIGN_UP}
                        </div>
                    </div>
                    <div className='row bottom-pad'>
                        <div className='dash-line' />
                        <div className='info-label split-label-padding' >
                            {OR}
                        </div>
                        <div className='dash-line' />
                    </div>
                    <div className='auth-btn-container oauth-bottom-padding'>
                        <Google login={true} />
                        <Facebook login={true} />
                        <Microsoft login={true} />
                        <Twitter login={true} />
                    </div>
                </div>
            </div>
        </div >
    );

}
export default (Login);





