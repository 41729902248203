import { ApiService } from '../../services/api.service';
import { history } from '../../../helpers/history';
import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE } from './signupConstants';
import { UserType } from '../../constants/constants';
import { alertActions } from '../alert/alertActions';
import { showAlert } from '../alert/showAlert';
import Cookies from 'js-cookie';

export function signup(data) {
    return dispatch => {
        dispatch(signupRequested());
        ApiService.callSignup(data).then(res => {
            dispatch(signupSuccess(res.data));
            if (res.data.status == 1) {
                dispatch(alertActions.success(res.data.message));
                if (res.data.verified == false) {
                    Cookies.set('verifyEmail', data.emailId)
                    var admin = false
                    if (data.userType == UserType.ADMIN) {
                        admin = true
                    }
                    history.push(
                        {
                            pathname: '/verifyEmail',
                            state: { admin: admin }
                        }
                    )
                }
                else {
                    if (data.userType == UserType.STUDENT) {
                        history.push('/preferences')
                    }
                    else {
                        history.push('/admin/cmsSites')
                    }
                }

            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));

            }

        })
            .catch(err => {
                dispatch(signupFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

const signupRequested = () => ({
    type: SIGNUP_REQUEST
});

const signupSuccess = data => ({
    type: SIGNUP_SUCCESS,
    payload: {
        ...data
    }
});

const signupFailure = error => ({
    type: SIGNUP_FAILURE,
    payload: {
        error
    }
});
