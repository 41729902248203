import { ApiService } from '../../services/api.service';
import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, RESET } from './resetPasswordConstants';
import { alertActions } from '../alert/alertActions';
import { showAlert } from '../alert/showAlert';
import Cookies from 'js-cookie';
import { TOKEN } from '../../constants/constants';

export function resetPassword(data) {
    return dispatch => {
        dispatch(resetPasswordRequested());
        ApiService.callResetPassword(data).then(res => {
            dispatch(resetPasswordSuccess(res.data));

            if (res.data.status == 1) {
                if (res.data.token) {
                    Cookies.set(TOKEN, res.data.token)
                    localStorage.setItem(TOKEN, res.data.token);
                }
                dispatch(alertActions.success(res.data.message));
            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));
            }

        })
            .catch(err => {
                dispatch(resetPasswordFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

export function resetPasswordReset() {
    return dispatch => {
        dispatch(reset());
    };
}

const resetPasswordRequested = () => ({
    type: RESET_PASSWORD_REQUEST
});

const resetPasswordSuccess = data => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: {
        ...data
    }
});

const resetPasswordFailure = error => ({
    type: RESET_PASSWORD_FAILURE,
    payload: {
        error
    }
});

const reset = () => ({
    type: RESET
});
