import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, GET_AUTH_LOGIN_TOKEN_REQUEST, GET_AUTH_LOGIN_TOKEN_SUCCESS, GET_AUTH_LOGIN_TOKEN_FAILURE, AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE } from './loginConstants';
const initialState = {
    loading: false,
    data: [],
    error: null
};

function LoginReducer(state = initialState, action = {}) {

    if (action.type == LOGIN_REQUEST || action.type == GET_AUTH_LOGIN_TOKEN_REQUEST || action.type == AUTH_LOGIN_REQUEST) {
        return {
            ...state,
            loading: true,
            error: null,
        };
    }
    else if (action.type == LOGIN_SUCCESS || action.type == GET_AUTH_LOGIN_TOKEN_SUCCESS || action.type == AUTH_LOGIN_SUCCESS) {
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null,
        };
    }
    else if (action.type == LOGIN_FAILURE || action.type == GET_AUTH_LOGIN_TOKEN_FAILURE || action.type == AUTH_LOGIN_FAILURE) {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
        };
    }
    else {
        return state;
    }

}

export default LoginReducer;