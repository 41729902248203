

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET = 'RESET';

export const ViewConstants = {
    CHOOSE_NEW_PASSWORD: 'Choose a new password',
    PASSWORD_PLACEHOLDER: `A few words you'll find easy to remember`,
    LOGIN_SUPPORT_TEXT: 'Still having trouble logging in?',
    GENERAL_ERROR_MESSAGE: 'Please double check your password and try again.',
    RESET_PASSWORD: 'RESET PASSWORD',
    CONFIRM_NEW_PASSWORD: 'Please confirm your password',
    PASSWORD_MISMATCH_ERROR_MESSAGE: 'Please make sure your passwords match',
    SUCCESS_TITLE: 'Password has been reset',
    RESET_PASSWORD_SUCCESS_MESSAGE: 'Please log in with your new password.',
    BACK_TO_LOGIN: 'Back to log in',
}