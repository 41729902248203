import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE } from './signupConstants';
const initialState = {
  loading: false,
  data: [],
  error: null
};

function SignupReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SIGNUP_SUCCESS:
      if (action.payload.status == "success") {
        localStorage.setItem('emailId', action.payload.data.emailId);
        localStorage.setItem('password', action.payload.data.password);
        localStorage.setItem('fullName', action.payload.data.fullName);
      }
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export default SignupReducer;