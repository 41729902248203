import { alertActions } from '../alert/alertActions';
import { history } from "../../../helpers/history";

export const showAlert = (dispatch, err) => {


    if (err.response && err.response.status == "401") {
        history.push('/login')
        dispatch(alertActions.error("Please login again"));
    }
    else {
        dispatch(alertActions.error(err.message));
    }

};