
export const GET_UNIVERSITY_LIST_REQUEST = 'GET_UNIVERSITY_LIST_REQUEST';
export const GET_UNIVERSITY_LIST_SUCCESS = 'GET_UNIVERSITY_LIST_SUCCESS';
export const GET_UNIVERSITY_LIST_FAILURE = 'GET_UNIVERSITY_LIST_FAILURE';


export const RESET = 'RESET';

export const ViewConstants = {
    TITLE: 'Your CMS sites',
    SUB_TITLE: 'Below are the sites that you have admin access to.',
}