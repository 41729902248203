export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const ViewConstants = {
    SIGN_UP_TITLE: 'Sign up for your account',
    EMAIL_ADDRESS: 'Email address',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    CREATE_PASSWORD: 'Create password (8+ characters)',
    PLEASE_CONFIRM_YOUR_PASSWORD: 'Please confirm your password',
    CREATE_ACCOUNT: 'CREATE ACCOUNT',
    LOG_IN_HELPER_TEXT: 'Already have an account?',
    LOG_IN: 'Log in',
    PASSWORD_MISMATCH_ERROR_MESSAGE: 'Please make sure your passwords match',
    SIGN_UP_ERROR_MESSAGE: 'Please double check your details and try again.',
    CREATE_ACCOUNT_HELPER_TEXT: 'Creating an account means you’re okay with our',
    TERMS_OF_SERVICE: 'Terms of Service',
    AND: 'and',
    PRIVACY_POLICY: 'Privacy Policy.',
    TERMS_AND_PRIVACY_ERROR_MESSAGE: 'Please indicate that you accept the Terms of Service and Privacy Policy'
}
