export const MAIN_HEADER = 'StudentBridge';
export const CONTINUE = 'CONTINUE';
export const PASSWORD_COUNT_MESSAGE = 'Password must have at least 8 characters';
export const LOST_PASSWORD = `Lost password?`;
export const TOKEN = 'token';
export const OR = 'OR';
export const OAUTH_SECRET = 'oauth_secret';
export const LOGIN_TYPE = 'loginType';
export const NORMAL = 'normal';
export const RETURL_URL = 'loggedInRedirect';
export const SAML_RESPONSE = 'SAMLResponse';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';


export const SignInForm = {
    ENTER_EMAIL: 'User name or email',
    ENTER_PASSWORD: 'Enter password',
    EMAIL_ID: 'emailId',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: "confirmPassword",
    REMEMBER_ME: 'rememberMe'
}
export const UserType = {
    STUDENT: 'student',
    ADMIN: 'admin'
}

export const PasswordStatus = {
    DEFAULT: 'default-color',
    WEAK: 'weak',
    FAIR: 'fair',
    GOOD: 'good',
    STRONG: 'strong',
    VERY_STRONG: 'very-strong',
}

export const PasswordValidations = {
    PASSWORD_VAIDATION_TITLE: 'Password requirements:',
    MINIMUM_8_CHARACTERS: 'Minimum 8 characters',
    MAXIMUM_20_CHARACTERS: 'Max 20 characters',
    MUST_INCLUDE_A_NUMERIC_DIGIT: 'Must include a numeric digit',
    MUST_INCLUDE_A_SPECIAL_CHARATER: 'Must include a special character',
    MUST_INCLUDE_AN_UPPER_CASE_LETTER: 'Must include an upper-case letter',
    CAN_INCLUDE_ONE_OR_MORE_SPACES: 'Can include one or more spaces',
    MUST_NOT_BEGIN_WITH_A_SPACE: 'Must not begin with a space'
}

export const BrandCheck = {
    STUDENT_BRIDGE: 'https://login.dev.studentbridge.com',
    // STUDENT_BRIDGE: 'http://localhost:3000',
    START: 'https://dev-example.login.',
    END: '.com',
    SAML_URL: 'https://226a9f2823bb.ngrok.io/?SAMLResponse=',
}