import { ApiService } from '../../services/api.service';
import { RESEND_CONFIRMATION_EMAIL_REQUEST, RESEND_CONFIRMATION_EMAIL_SUCCESS, RESEND_CONFIRMATION_EMAIL_FAILURE } from './verifyEmailConstants';
import { alertActions } from '../alert/alertActions';
import { showAlert } from '../alert/showAlert';

export function resendConfirmationEmail(data) {
    return dispatch => {
        dispatch(resendConfirmationEmailRequested());
        ApiService.callResendConfirmationEmail(data).then(res => {
            dispatch(resendConfirmationEmailSuccess(res.data));

            if (res.data.status == 1) {
                dispatch(alertActions.success(res.data.message));
            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));
            }
        })
            .catch(err => {
                dispatch(resendConfirmationEmailFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

const resendConfirmationEmailRequested = () => ({
    type: RESEND_CONFIRMATION_EMAIL_REQUEST
});

const resendConfirmationEmailSuccess = data => ({
    type: RESEND_CONFIRMATION_EMAIL_SUCCESS,
    payload: {
        ...data
    }
});

const resendConfirmationEmailFailure = error => ({
    type: RESEND_CONFIRMATION_EMAIL_FAILURE,
    payload: {
        error
    }
});
