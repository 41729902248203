import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './app/route/private-route';
import PublicRoute from './app/route/public-route';
import { history } from '../src/helpers/history';
import Login from './app/pages/login/login';
import AdminLogin from './app/pages/login/admin/login/login';
import ForgotPassword from './app/pages/forgotPassword/forgotPassword';
import Signup from './app/pages/signup/signup';
import AdminSignup from './app/pages/signup/admin/signup/signup';
import VerifyEmail from './app/pages/verifyEmail/verifyEmail';
import ResetPassword from './app/pages/resetPassword/resetPassword';
import ChangePassword from './app/pages/changePassword/changePassword';
import Preferences from './app/pages/preferences/preferences';
import CMSSites from './app/pages/admin/cmsSites/cmsSites';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Routes = () => {

    return (
        <div >
            <ToastContainer />
            <Router history={history}>
                <Switch>
                    {/* <Redirect exact from="/" to="login" />
                    <PublicRoute path="/login" component={Login} /> */}
                    <Redirect exact from='/' to='preferences' />
                    <PublicRoute path='/admin/login' component={AdminLogin} />
                    <PublicRoute path='/login' component={Login} />
                    <PublicRoute path='/forgotPassword' component={ForgotPassword} />
                    <PublicRoute path='/signup' component={Signup} />
                    <PublicRoute path='/admin/signup' component={AdminSignup} />
                    <PublicRoute path='/resetPassword' component={ResetPassword} />
                    <PublicRoute path='/verifyEmail' component={VerifyEmail} />
                    <PrivateRoute path='/changePassword' component={ChangePassword} />
                    <PrivateRoute path='/preferences' component={Preferences} />
                    <PrivateRoute path='/admin/cmsSites' component={CMSSites} />
                </Switch>
            </Router>
        </div>
    );
}



export default (Routes);
