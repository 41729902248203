import React, { useState, useEffect, useContext } from 'react';
import { ViewConstants } from './sideBarConstants';
import Cookies from 'js-cookie';
import { history } from '../../../helpers/history';
import { TOKEN, LOGIN_TYPE, NORMAL, SAML_RESPONSE, FIRST_NAME, LAST_NAME } from '../../constants/constants';
import useCollapse from 'react-collapsed';
import AppContext from '../../components/appContext/appContext';

const SideBar = (props) => {

    const myContext = useContext(AppContext);

    const [width, setWidth] = useState(window.innerWidth);
    const { getCollapseProps, getToggleProps } = useCollapse();

    const handleMyPreferences = () => {
        history.push('/preferences')
    }
    const handleCMSSites = () => {
        history.push('/admin/cmsSites')
    }
    const handleChangePassword = () => {
        history.push(
            {
                pathname: '/changePassword',
                state: { admin: props.admin }
            }
        )
    }
    const handleLogout = () => {
        clearCredentialsFromStorage();
        if (props.admin) {
            history.push('/admin/login')
        } else {
            history.push('/login')
        }
    }
    const clearCredentialsFromStorage = () => {
        Cookies.remove(TOKEN);
        Cookies.remove(LOGIN_TYPE);
        Cookies.remove(FIRST_NAME);
        Cookies.remove(LAST_NAME);
        localStorage.removeItem(SAML_RESPONSE)
    }

    useEffect(() => {

        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    return (
        <div className='side-bar-main-box'>
            {width < 750 ?
                <div>
                    <button {...getToggleProps()} className='hamburger-btn' style={{ marginLeft: '10px', marginTop: '15px', marginBottom: '13px', maxHeight: '38px', minHeight: '38px', maxWidth: '34px', minWidth: '34px' }} />
                    <section {...getCollapseProps()} className='side-bar-shadow'>
                        {props.admin ?
                            <div className={props.cmsSites ? 'side-bar-item-selected' : 'side-bar-item'} onClick={handleCMSSites}>
                                {props.cmsSites && <div className='select-indicator-size' style={myContext.buttonStyle} />}
                                <div className={props.cmsSites ? 'side-bar-select-text' : 'side-bar-text'}>
                                    {ViewConstants.CMS_SITES}
                                </div>
                            </div>
                            : <div className={props.preferences ? 'side-bar-item-selected' : 'side-bar-item'} onClick={handleMyPreferences}>
                                {props.preferences && <div className='select-indicator-size' style={myContext.buttonStyle} />}
                                <div className={props.preferences ? 'side-bar-select-text' : 'side-bar-text'}>
                                    {ViewConstants.MY_PREFERENCES}
                                </div>
                            </div>
                        }
                        {/* <div className={props.cmsSites ? 'side-bar-item-selected' : 'side-bar-item'} onClick={handleCMSSites}>
                            {props.cmsSites && <div className='select-indicator-size' style={myContext.buttonStyle} />}
                            <div className={props.cmsSites ? 'side-bar-select-text' : 'side-bar-text'}>
                                {ViewConstants.CMS_SITES}
                            </div>
                        </div> */}
                        {Cookies.get(LOGIN_TYPE) == NORMAL &&
                            <div className={props.securityAndPassword ? 'side-bar-item-selected' : 'side-bar-item'} onClick={handleChangePassword}>
                                {props.securityAndPassword && <div className='select-indicator-size' style={myContext.buttonStyle} />}
                                <div className={props.securityAndPassword ? 'side-bar-select-text' : 'side-bar-text'}>
                                    {ViewConstants.PASSWORD_AND_SECURITY}
                                </div>
                            </div>
                        }
                        <div className={'side-bar-item'} onClick={handleLogout}>
                            <div className={'side-bar-text'}>
                                {ViewConstants.LOGOUT}
                            </div>
                        </div>

                    </section>
                </div> :
                <div class='slider'>
                    {props.admin ?
                        <div className={props.cmsSites ? 'side-bar-item-selected' : 'side-bar-item'} onClick={handleCMSSites}>
                            {props.cmsSites && <div className='select-indicator-size' style={myContext.buttonStyle} />}
                            <div className={props.cmsSites ? 'side-bar-select-text' : 'side-bar-text'}>
                                {ViewConstants.CMS_SITES}
                            </div>
                        </div>
                        :
                        <div className={props.preferences ? 'side-bar-item-selected' : 'side-bar-item'} onClick={handleMyPreferences}>
                            {props.preferences && <div className='select-indicator-size' style={myContext.buttonStyle} />}
                            <div className={props.preferences ? 'side-bar-select-text' : 'side-bar-text'}>
                                {ViewConstants.MY_PREFERENCES}
                            </div>
                        </div>
                    }
                    {/* <div className={props.cmsSites ? 'side-bar-item-selected' : 'side-bar-item'} onClick={handleCMSSites}>
                        {props.cmsSites && <div className='select-indicator-size' style={myContext.buttonStyle} />}
                        <div className={props.cmsSites ? 'side-bar-select-text' : 'side-bar-text'}>
                            {ViewConstants.CMS_SITES}
                        </div>
                    </div> */}
                    {Cookies.get(LOGIN_TYPE) == NORMAL &&
                        <div className={props.securityAndPassword ? 'side-bar-item-selected' : 'side-bar-item'} onClick={handleChangePassword}>
                            {props.securityAndPassword && <div className='select-indicator-size' style={myContext.buttonStyle} />}
                            <div className={props.securityAndPassword ? 'side-bar-select-text' : 'side-bar-text'}>
                                {ViewConstants.PASSWORD_AND_SECURITY}
                            </div>
                        </div>
                    }
                    <div className={'side-bar-item'} onClick={handleLogout}>
                        <div className={'side-bar-text'}>
                            {ViewConstants.LOGOUT}
                        </div>
                    </div>
                </div>
            }
        </div >
    );

}
export default (SideBar);