import { ApiService } from '../../services/api.service';
import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE, RESET } from './forgotPasswordConstants';
import { alertActions } from '../alert/alertActions';
import { showAlert } from '../alert/showAlert';

export function forgotPassword(data) {
    return dispatch => {
        dispatch(forgotPasswordRequested());
        ApiService.callForgotPassword(data).then(res => {

            if (res.data.status == 1) {
                dispatch(alertActions.success(res.data.message));
                dispatch(forgotPasswordSuccess(res.data));
            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));
                dispatch(forgotPasswordFailure(res.data));

            }

        })
            .catch(err => {
                dispatch(forgotPasswordFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

export function forgotPasswordReset() {
    return dispatch => {
        dispatch(reset());
    };
}

const forgotPasswordRequested = () => ({
    type: FORGOT_PASSWORD_REQUEST
});

const forgotPasswordSuccess = data => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: {
        ...data
    }
});

const forgotPasswordFailure = error => ({
    type: FORGOT_PASSWORD_FAILURE,
    payload: {
        error
    }
});

const reset = () => ({
    type: RESET
});
