import { ApiService } from '../services/api.service';
import { UNIVERSITY_DESIGN_REQUEST, UNIVERSITY_DESIGN_SUCCESS, UNIVERSITY_DESIGN_FAILURE, RESET } from './generalConstants';
import { alertActions } from '../pages/alert/alertActions';
import { showAlert } from '../pages/alert/showAlert';

export function universityDesign() {
    return dispatch => {
        dispatch(universityDesignRequested());
        ApiService.callUniversityDesign().then(res => {

            if (res.data.status == 1) {
                dispatch(universityDesignSuccess(res.data));
            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));
                dispatch(universityDesignFailure(res.data));
            }
        })
            .catch(err => {
                dispatch(universityDesignFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

export function universityDesignReset() {
    return dispatch => {
        dispatch(reset());
    };
}

const universityDesignRequested = () => ({
    type: UNIVERSITY_DESIGN_REQUEST
});

const universityDesignSuccess = data => ({
    type: UNIVERSITY_DESIGN_SUCCESS,
    payload: {
        ...data
    }
});

const universityDesignFailure = error => ({
    type: UNIVERSITY_DESIGN_FAILURE,
    payload: {
        error
    }
});

const reset = () => ({
    type: RESET
});