import { GET_UNIVERSITY_LIST_REQUEST, GET_UNIVERSITY_LIST_SUCCESS, GET_UNIVERSITY_LIST_FAILURE, RESET } from './cmsSitesConstants';
const initialState = {
    loading: false,
    data: [],
    error: null,
    success: false
};

function CmsSitesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_UNIVERSITY_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_UNIVERSITY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
                success: true
            };
        case GET_UNIVERSITY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                success: false
            };

        case RESET:
            return {
                ...state,
                loading: false,
                error: null,
                success: false
            };
        default:
            return state;
    }
}

export default CmsSitesReducer;