import React, { useState } from 'react';
import { resendConfirmationEmail } from './verifyEmailActions';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { ViewConstants } from './verifyEmailConstants';
import { UserType } from '../../constants/constants';

const VerifyEmail = (props) => {

    const [inputs] = useState({
        emailId: Cookies.get('verifyEmail'),
        userType: props.location.state.admin ? UserType.ADMIN : UserType.STUDENT
    });

    const { emailId } = inputs;

    const dispatch = useDispatch();

    // Verify email api

    const handleResendLink = (e) => {
        dispatch(resendConfirmationEmail(inputs));
    }

    return (
        <div>
            <div className='main-top' />
            <div className="main-box">
                <div className='box-container'>
                    <div className={'main-header main-header-bottom'}>
                        {ViewConstants.TITLE}
                    </div>

                    <div className='sub-header-verify'>
                        <div className='sub-header-normal-verify'>
                            {ViewConstants.VERIFY_EMAIL_CONFIRM_MESSAGE}
                        </div>
                        <div className='sub-header-bold-verify'>
                            {emailId}
                        </div>
                    </div>
                    <div className='solid-line'>
                    </div>
                    <div className='info-label-verify'>
                        {ViewConstants.CONFIRM_EMAIL_QUESTION}
                    </div>
                    <div className='row helper-padding bottom-pad'>
                        <div className='info-label-verify margin-right'>
                            {ViewConstants.CONFIRM_EMAIL_HELPER_TEXT}
                        </div>
                        <div className='link-btn blue-link-btn' onClick={handleResendLink}>
                            {ViewConstants.RESEND_EMAIL}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default (VerifyEmail);





