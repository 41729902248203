import React from 'react';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import MicrosoftLogin from "react-microsoft-login";
import { AuthConstants } from './OAuth2Constants';
import { useDispatch } from 'react-redux';
import { login, getAuthLoginToken } from '../../pages/login/loginActions'

export const Google = (props) => {

    const dispatch = useDispatch();

    const responseGoogleLoginSuccess = (response) => {
        const data = {
            emailId: response.profileObj.email,
            fullName: response.profileObj.name,
            authId: response.googleId,
            authType: AuthConstants.TYPE_GOOGLE,
        }
        dispatch(login(data));
    }

    const responseGoogleLoginFailed = (response) => {
        console.log("RESPONSE GOOGLE LOGIN FAILED= ", response);
    }

    return (
        <div>
            <GoogleLogin
                className='plain-btn input-bottom-pad google-btn'
                clientId={AuthConstants.GOOGLE_CLIENT_ID}
                buttonText=""
                icon={false}
                onSuccess={responseGoogleLoginSuccess}
                onFailure={responseGoogleLoginFailed}
                cookiePolicy={'single_host_origin'} />
            <div className='google-logo' />
            <div className='plain-btn-text google-btn-text-position'>
                {props.login ? AuthConstants.LOGIN_WITH_GOOGLE : AuthConstants.SIGN_UP_WITH_GOOGLE}
            </div>
        </div>
    )
}

export const Facebook = (props) => {

    const dispatch = useDispatch();

    const FacebookLoginUserInfo = (userInfo) => {
        const data = {
            emailId: userInfo.email,
            fullName: userInfo.name,
            authId: userInfo.id,
            authType: AuthConstants.TYPE_FACEBOOK,
        }
        dispatch(login(data));
    }

    const facebookLoginFailed = (response) => {
        console.log("FB login failed = ", response);
    }

    return (
        <div>
            <FacebookLogin
                cssClass='plain-btn input-bottom-pad fb-btn'
                appId={AuthConstants.FACEBOOK_APP_ID}
                autoLoad={false}
                fields="name,email,picture"
                callback={FacebookLoginUserInfo}
                onFailure={facebookLoginFailed}
                textButton='' />
            <div className='fb-logo' />
            <div className='plain-btn-text fb-btn-text-position'>
                {props.login ? AuthConstants.LOGIN_WITH_FB : AuthConstants.SIGN_UP_WITH_FB}
            </div>
        </div>
    )
}

export const Microsoft = (props) => {

    const dispatch = useDispatch();

    const responseMicrosoft = (error, response) => {
        if (error) {
            console.log("MICROSOFT ERROR = ", error);
        }
        else {
            const data = {
                emailId: response.mail,
                fullName: response.displayName,
                authId: response.id,
                authType: AuthConstants.TYPE_MICROSOFT,
            }
            dispatch(login(data));
        }
    };

    return (
        <MicrosoftLogin
            className='microsoft-plain-btn'
            clientId={AuthConstants.MICROSOFT_CLIENT_ID}
            withUserData={true}
            authCallback={responseMicrosoft}>
            <div>
                <div className='microsoft-plain-btn input-bottom-pad' />
                <div className='microsoft-logo' />
                <div className='plain-btn-text microsoft-btn-text-position'>
                    {props.login ? AuthConstants.LOGIN_WITH_MICROSOFT : AuthConstants.SIGN_UP_WITH_MICROSOFT}
                </div>
            </div>
        </MicrosoftLogin >
    )
}

export const Twitter = (props) => {

    const dispatch = useDispatch();
    const handleGetAuthLoginToken = (e) => {
        const data = {
            callbackUrl: window.location.href
        }
        dispatch(getAuthLoginToken(data))
    }

    return (
        <div onClick={handleGetAuthLoginToken} >
            <div className='twitter-plain-btn' />
            <div className='twitter-logo' />
            <div className='plain-btn-text twitter-btn-text-position'>
                {props.login ? AuthConstants.LOGIN_WITH_TWITTER : AuthConstants.SIGN_UP_WITH_TWITTER}
            </div>
        </div>
    )
}