import { ApiService } from '../../services/api.service';
import { history } from '../../../helpers/history';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, GET_AUTH_LOGIN_TOKEN_REQUEST, GET_AUTH_LOGIN_TOKEN_SUCCESS, GET_AUTH_LOGIN_TOKEN_FAILURE, AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE } from './loginConstants';
import { alertActions } from '../alert/alertActions';
import { showAlert } from '../alert/showAlert';
import Cookies from 'js-cookie';
import { TOKEN, OAUTH_SECRET, LOGIN_TYPE, NORMAL, UserType, SAML_RESPONSE, FIRST_NAME, LAST_NAME } from '../../constants/constants';

export function login(data) {
    return dispatch => {
        dispatch(loginRequested());
        ApiService.callLogin(data).then(res => {
            dispatch(loginSuccess(res.data));
            if (res.data.status == 1) {
                if (res.data.SAMLResponse) {
                    //SAML Handling
                    localStorage.setItem(SAML_RESPONSE, res.data.SAMLResponse);
                }
                if (res.data.userData.firstName) {
                    Cookies.set(FIRST_NAME, res.data.userData.firstName)
                }
                if (res.data.userData.lastName) {
                    Cookies.set(LAST_NAME, res.data.userData.lastName)
                }
                if (res.data.token) {
                    Cookies.set(TOKEN, res.data.token)
                    if (!data.authType) {
                        Cookies.set(LOGIN_TYPE, NORMAL)
                        if (data.userType == UserType.STUDENT) {
                            history.push('/preferences')
                        }
                        else {
                            history.push('/admin/cmsSites')
                        }
                    }
                    else {
                        history.push('/preferences')
                    }
                }
            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));
            }
        })
            .catch(err => {
                dispatch(loginFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

const loginRequested = () => ({
    type: LOGIN_REQUEST
});

const loginSuccess = data => ({
    type: LOGIN_SUCCESS,
    payload: {
        ...data
    }
});

const loginFailure = error => ({
    type: LOGIN_FAILURE,
    payload: {
        error
    }
});

export function getAuthLoginToken(data) {
    return dispatch => {

        dispatch(getAuthLoginTokenRequested());
        ApiService.callGetAuthLoginToken(data).then(res => {
            if (res.data.oauth_secret) {
                Cookies.set(OAUTH_SECRET, res.data.oauth_secret)
                window.open('https://api.twitter.com/oauth/authorize?oauth_token=' + res.data.oauth_token, '_self');
            }
        })
            .catch(err => {
                dispatch(getAuthLoginTokenFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

const getAuthLoginTokenRequested = () => ({
    type: GET_AUTH_LOGIN_TOKEN_REQUEST
});

const getAuthLoginTokenSuccess = data => ({
    type: GET_AUTH_LOGIN_TOKEN_SUCCESS,
    payload: {
        ...data
    }
});

const getAuthLoginTokenFailure = error => ({
    type: GET_AUTH_LOGIN_TOKEN_FAILURE,
    payload: {
        error
    }
});

export function authLogin(data) {
    return dispatch => {
        dispatch(authLoginRequested());
        ApiService.callAuthLogin(data).then(res => {
            dispatch(authLoginSuccess(res.data));

            if (res.data.status == 1) {
                if (res.data.token) {
                    Cookies.set(TOKEN, res.data.token)
                    history.push('/preferences')
                }
            }
            else if (res.data.status == 0) {
                dispatch(alertActions.error(res.data.message));
            }
        })
            .catch(err => {
                dispatch(authLoginFailure(err.message));
                showAlert(dispatch, err);
            });
    };
}

const authLoginRequested = () => ({
    type: AUTH_LOGIN_REQUEST
});

const authLoginSuccess = data => ({
    type: AUTH_LOGIN_SUCCESS,
    payload: {
        ...data
    }
});

const authLoginFailure = error => ({
    type: AUTH_LOGIN_FAILURE,
    payload: {
        error
    }
});