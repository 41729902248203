import React, { useState, useEffect, useContext } from 'react';
import { getUniversityList } from './cmsSitesActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/loader/loader';
import { ViewConstants } from './cmsSitesConstants';
import SideBar from '../../../components/sideBar/sideBar';


const CmsSites = () => {

    const [getUniversityListInputs, setGetUniversityListInputs] = useState({
    });

    const response = useSelector(state => state.cmsSitesReducerData.data);
    const [universityList, setUniversityList] = useState([]);
    const [showError, setShowError] = useState(false);
    const loading = useSelector(state => state.cmsSitesReducerData.loading);

    const dispatch = useDispatch();

    const universitySelected = (index) => {
    };

    // Get University List

    // useEffect(() => {
    //     dispatch(getUniversityList(getUniversityListInputs));
    // }, []);

    // Handle university list api response

    // useEffect(() => {
    //     setUniversityList(response.data)
    // }, [response]);


    // Dummy university list

    useEffect(() => {
        setUniversityList([1, 2, 3])
    }, []);


    return (

        <div>
            <div className='main-top-private' />
            <div className="main-box-large">
                <SideBar cmsSites={true} admin={true} />
                <div className={'box-container-cms-sites ' + (loading ? 'border-radius' : 'no-border-radius')}>
                    {loading && <Loader />}
                    <div className={'main-header main-header-large ' + (showError ? 'main-header-bottom-error' : 'home-header-bottom')}>
                        {ViewConstants.TITLE}
                    </div>
                    <div className='sub-header-normal input-bottom-pad'>
                        <div className='sub-header-normal'>
                            {ViewConstants.SUB_TITLE}
                        </div>
                    </div>
                    <div className='univeristy-holder'>
                        <div className='solid-line-cms-sites line-margin' />
                        <div className='line-button-bottom'>
                            {universityList && universityList.length > 0 &&
                                <div className='university-container-start'>
                                    {universityList.map((item, index) => {
                                        return (
                                            <div className={'university-bottom-margin university-box ' + (((index + 1) % 3) != 0 && 'university-right-margin')}>
                                                <Button className='university-box' onClick={() => universitySelected(index)}>
                                                    {/* Replace with the image from the api */}
                                                    <div className='university-logo' />
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

}
export default (CmsSites);


