import { RESEND_CONFIRMATION_EMAIL_REQUEST, RESEND_CONFIRMATION_EMAIL_SUCCESS, RESEND_CONFIRMATION_EMAIL_FAILURE } from './verifyEmailConstants';
const initialState = {
    loading: false,
    data: [],
    error: null
};

function VerifyEmailReducer(state = initialState, action = {}) {
    switch (action.type) {
        case RESEND_CONFIRMATION_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case RESEND_CONFIRMATION_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case RESEND_CONFIRMATION_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
}

export default VerifyEmailReducer;