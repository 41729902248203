import Cookies from 'js-cookie';
import { TOKEN } from '../constants/constants';


const IsLoggedIn = () => {
    if (Cookies.get(TOKEN)) {
        return true;
    }
    return false

};

const AuthService = { IsLoggedIn };
export default AuthService;