import { HttpClient } from './http.service';
import { APIPath } from '../config';
import Cookies from 'js-cookie';
import { TOKEN } from '../constants/constants';


const BASE_URL = `${APIPath}`;
const headersConfig = () => ({

    'Accept': 'application/json',
    'x-access-token': Cookies.get(TOKEN)
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-type': 'application/json'
});

// University design
const callUniversityDesign = () => {
    return HttpClient.get(`${BASE_URL}universityDesign`, { headers: headersConfig() });
};

//Login
const callLogin = (data) => {
    return HttpClient.post(`${BASE_URL}login`, data, { headers: headersConfig() });
};

//Signup
const callSignup = (data) => {
    return HttpClient.post(`${BASE_URL}signup`, data, { headers: headersConfig() });
};

// Get auth login token
const callGetAuthLoginToken = (data) => {
    return HttpClient.post(`${BASE_URL}requestToken`, data, { headers: headersConfig() });
};

//Auth login 
const callAuthLogin = (data) => {
    return HttpClient.post(`${BASE_URL}accessToken`, data, { headers: headersConfig() });
};

//forgot password
const callForgotPassword = (data) => {
    return HttpClient.post(`${BASE_URL}forgotPassword`, data, { headers: headersConfig() });
};

//reset password
const callResetPassword = (data) => {
    return HttpClient.put(`${BASE_URL}resetPassword`, data, { headers: headersConfig() });
};

//resend confirmation email
const callResendConfirmationEmail = (data) => {
    return HttpClient.post(`${BASE_URL}resend_confirmation`, data, { headers: headersConfig() });
};

// change password
const callChangePassword = (data) => {
    return HttpClient.put(`${BASE_URL}changePassword`, data, { headers: headersConfig() });
};

// get preferences
const callGetPreferences = () => {
    return HttpClient.get(`${BASE_URL}getQuestionSeries`, { headers: headersConfig() });
};

// submit preferences
const callSubmitPreferences = (data) => {
    return HttpClient.post(`${BASE_URL}answerQuestionSeries`, data, { headers: headersConfig() });
};

// Get university list
const callGetUniversityList = (data) => {
    return HttpClient.post(`${BASE_URL}getUniversityList`, data, { headers: headersConfig() });
};

export const ApiService = {
    callUniversityDesign,
    callLogin,
    callSignup,
    callGetAuthLoginToken,
    callAuthLogin,
    callForgotPassword,
    callResetPassword,
    callResendConfirmationEmail,
    callChangePassword,
    callGetPreferences,
    callSubmitPreferences,
    callGetUniversityList
};
