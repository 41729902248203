
export const GET_PREFERENCES_REQUEST = 'GET_PREFERENCES_REQUEST';
export const GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS';
export const GET_PREFERENCES_FAILURE = 'GET_PREFERENCES_FAILURE';
export const SUBMIT_PREFERENCES_REQUEST = 'SUBMIT_PREFERENCES_REQUEST';
export const SUBMIT_PREFERENCES_SUCCESS = 'SUBMIT_PREFERENCES_SUCCESS';
export const SUBMIT_PREFERENCES_FAILURE = 'SUBMIT_PREFERENCES_FAILURE';

export const RESET = 'RESET';

export const ViewConstants = {
    TITLE: 'What do you consider most important when choosing a school?',
    SUB_TITLE: 'Select as many options as you want. Your preferences will help us customize your experience.',
    SAVE_MY_PREFERENCES: 'SAVE MY PREFERENCES',
    PREFERENCES_EMPTY_MESSAGE: 'Please select your preferences'
}